import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { 
  Package, 
  ClipboardList, 
  CreditCard, 
  LayoutDashboard, 
  Settings,
  TrendingUp,
  DollarSign,
  BarChart2,
  User,
  Shield
} from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useAdminStore } from '../stores/adminStore';

export default function Navigation() {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { isAdmin } = useAdminStore();

  const isActive = (path: string) => location.pathname === path;

  const handleNavigation = (path: string) => {
    navigate(path);
  };

  return (
    <nav className="fixed bottom-0 left-0 right-0 bg-gray-800 border-t border-gray-700 pb-[var(--sab)]">
      <div className="max-w-7xl mx-auto px-2">
        <div className="flex justify-between items-center h-14">
          {/* Bouton Stock */}
          <button
            onClick={() => handleNavigation('/stock')}
            className={`flex flex-col items-center px-1 py-1.5 transition-all duration-200 ${
              isActive('/stock') 
                ? 'text-red-500 scale-105' 
                : 'text-gray-400 hover:text-white hover:scale-100'
            }`}
          >
            {isActive('/stock') ? <TrendingUp size={20} /> : <Package size={20} />}
            <span className="text-[10px] font-medium">{t('nav.stock')}</span>
          </button>

          {/* Bouton Commandes */}
          <button
            onClick={() => handleNavigation('/consignment')}
            className={`flex flex-col items-center px-1 py-1.5 transition-all duration-200 ${
              isActive('/consignment') 
                ? 'text-red-500 scale-105' 
                : 'text-gray-400 hover:text-white hover:scale-100'
            }`}
          >
            <ClipboardList size={20} />
            <span className="text-[10px] font-medium">{t('nav.consignment')}</span>
          </button>

          {/* Bouton Ventes */}
          <button
            onClick={() => handleNavigation('/sales')}
            className={`flex flex-col items-center px-1 py-1.5 transition-all duration-200 ${
              isActive('/sales') 
                ? 'text-red-500 scale-105' 
                : 'text-gray-400 hover:text-white hover:scale-100'
            }`}
          >
            {isActive('/sales') ? <DollarSign size={20} /> : <CreditCard size={20} />}
            <span className="text-[10px] font-medium">{t('nav.sales')}</span>
          </button>

          {/* Bouton Tableau de Bord */}
          <button
            onClick={() => handleNavigation('/dashboard')}
            className={`flex flex-col items-center px-1 py-1.5 transition-all duration-200 ${
              isActive('/dashboard') 
                ? 'text-red-500 scale-105' 
                : 'text-gray-400 hover:text-white hover:scale-100'
            }`}
          >
            {isActive('/dashboard') ? <BarChart2 size={20} /> : <LayoutDashboard size={20} />}
            <span className="text-[10px] font-medium">{t('nav.dashboard')}</span>
          </button>

          {/* Bouton Admin (uniquement si admin) */}
          {isAdmin && (
            <button
              onClick={() => handleNavigation('/admin')}
              className={`flex flex-col items-center px-1 py-1.5 transition-all duration-200 ${
                isActive('/admin') 
                  ? 'text-red-500 scale-105' 
                  : 'text-gray-400 hover:text-white hover:scale-100'
              }`}
            >
              <Shield size={20} />
              <span className="text-[10px] font-medium">Admin</span>
            </button>
          )}

          {/* Bouton Réglages */}
          <button
            onClick={() => handleNavigation('/settings')}
            className={`flex flex-col items-center px-1 py-1.5 transition-all duration-200 ${
              isActive('/settings') 
                ? 'text-red-500 scale-105' 
                : 'text-gray-400 hover:text-white hover:scale-100'
            }`}
          >
            {isActive('/settings') ? <User size={20} /> : <Settings size={20} />}
            <span className="text-[10px] font-medium">{t('nav.settings')}</span>
          </button>
        </div>
      </div>
    </nav>
  );
}
