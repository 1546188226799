import React from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { X, Plus } from 'lucide-react';
import { RecurrencePeriod } from '../stores/expenseStore';

const schema = z.object({
  amount: z.number().min(0, 'Le montant doit être positif'),
  type: z.enum(['boost', 'abonnement', 'autre']),
  description: z.string().optional(),
  is_recurring: z.boolean(),
  recurrence_period: z.enum(['weekly', 'monthly', 'yearly', '']).transform(v => v === '' ? null : v),
  date: z.string()
});

type FormData = z.infer<typeof schema>;

interface AddExpenseModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: FormData) => void;
}

function AddExpenseModal({ isOpen, onClose, onSubmit }: AddExpenseModalProps) {
  const { register, handleSubmit, reset, watch, formState: { errors } } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: {
      date: new Date().toISOString().split('T')[0],
      is_recurring: false,
      recurrence_period: ''
    }
  });

  const isRecurring = watch('is_recurring');

  if (!isOpen) return null;

  const handleFormSubmit = (data: FormData) => {
    onSubmit(data);
    reset();
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
      <div className="bg-gray-800 rounded-lg w-full max-w-md">
        <div className="flex justify-between items-center p-4 border-b border-gray-700">
          <h2 className="text-lg font-semibold">Ajouter une dépense</h2>
          <button onClick={onClose} className="text-gray-400 hover:text-white">
            <X className="h-6 w-6" />
          </button>
        </div>

        <form onSubmit={handleSubmit(handleFormSubmit)} className="p-4 space-y-4">
          <div>
            <label className="block text-sm font-medium mb-1">Montant</label>
            <input
              type="number"
              step="0.01"
              {...register('amount', { valueAsNumber: true })}
              className="w-full bg-gray-700 rounded-lg p-2 focus:ring-2 focus:ring-red-500 focus:outline-none"
              placeholder="0.00"
            />
            {errors.amount && (
              <p className="text-red-500 text-sm mt-1">{errors.amount.message}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium mb-1">Type</label>
            <select
              {...register('type')}
              className="w-full bg-gray-700 rounded-lg p-2 focus:ring-2 focus:ring-red-500 focus:outline-none"
            >
              <option value="boost">Boost</option>
              <option value="abonnement">Abonnement</option>
              <option value="autre">Autre</option>
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium mb-1">Date</label>
            <input
              type="date"
              {...register('date')}
              className="w-full bg-gray-700 rounded-lg p-2 focus:ring-2 focus:ring-red-500 focus:outline-none"
            />
          </div>

          <div>
            <label className="block text-sm font-medium mb-1">Description (optionnelle)</label>
            <textarea
              {...register('description')}
              className="w-full bg-gray-700 rounded-lg p-2 focus:ring-2 focus:ring-red-500 focus:outline-none"
              rows={3}
              placeholder="Description de la dépense"
            />
          </div>

          <div className="space-y-2">
            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                {...register('is_recurring')}
                className="rounded border-gray-600 bg-gray-700 text-red-500 focus:ring-red-500"
              />
              <label className="text-sm">Dépense récurrente</label>
            </div>

            {isRecurring && (
              <select
                {...register('recurrence_period')}
                className="w-full bg-gray-700 rounded-lg p-2 focus:ring-2 focus:ring-red-500 focus:outline-none"
              >
                <option value="">Sélectionner une périodicité</option>
                <option value="weekly">Hebdomadaire</option>
                <option value="monthly">Mensuelle</option>
                <option value="yearly">Annuelle</option>
              </select>
            )}
          </div>

          <button
            type="submit"
            className="w-full bg-red-500 text-white py-2 rounded-lg hover:bg-red-600"
          >
            Ajouter la dépense
          </button>
        </form>
      </div>
    </div>
  );
}

export default AddExpenseModal;