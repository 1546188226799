import React, { useState } from 'react';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { Trash2, ChevronDown, ChevronUp } from 'lucide-react';
import { Expense } from '../stores/expenseStore';

interface ExpensesListProps {
  expenses: Expense[];
  onDelete: (id: string) => void;
}

const recurrenceLabels = {
  weekly: 'Hebdomadaire',
  monthly: 'Mensuelle',
  yearly: 'Annuelle'
};

function ExpensesList({ expenses, onDelete }: ExpensesListProps) {
  const [showAll, setShowAll] = useState(false);
  const [selectedType, setSelectedType] = useState<'all' | 'boost' | 'abonnement' | 'autre'>('all');

  const filteredExpenses = expenses.filter(expense => 
    selectedType === 'all' || expense.type === selectedType
  );

  const displayedExpenses = showAll ? filteredExpenses : filteredExpenses.slice(0, 5);
  const hasMoreExpenses = filteredExpenses.length > 5;

  return (
    <div className="space-y-4">
      {/* Filtres */}
      <div className="flex gap-2 overflow-x-auto pb-2">
        {[
          { id: 'all', label: 'Tout' },
          { id: 'boost', label: 'Boost' },
          { id: 'abonnement', label: 'Abonnement' },
          { id: 'autre', label: 'Autre' }
        ].map(filter => (
          <button
            key={filter.id}
            onClick={() => setSelectedType(filter.id as any)}
            className={`px-4 py-2 rounded-full whitespace-nowrap transition-all duration-200 ${
              selectedType === filter.id 
                ? 'bg-red-500 scale-105' 
                : 'bg-gray-800 hover:bg-gray-700'
            }`}
          >
            {filter.label}
          </button>
        ))}
      </div>

      {/* Liste des dépenses */}
      <div className="space-y-3">
        {displayedExpenses.map(expense => (
          <div key={expense.id} className="bg-gray-700/50 p-3 rounded-lg">
            <div className="flex items-center justify-between">
              <div>
                <div className="flex items-center gap-2">
                  <span className="font-medium">{expense.amount}€</span>
                  <span className="text-sm text-gray-400 capitalize">{expense.type}</span>
                  {expense.is_recurring && expense.recurrence_period && (
                    <span className="text-xs bg-blue-500/20 text-blue-500 px-2 py-0.5 rounded-full">
                      {recurrenceLabels[expense.recurrence_period]}
                    </span>
                  )}
                </div>
                {expense.description && (
                  <p className="text-sm text-gray-400 mt-1">{expense.description}</p>
                )}
              </div>
              <div className="flex items-center gap-2">
                <span className="text-sm text-gray-400">
                  {format(new Date(expense.date), 'dd/MM/yyyy', { locale: fr })}
                </span>
                <button
                  onClick={() => onDelete(expense.id)}
                  className="p-1 text-red-500 hover:bg-red-500/20 rounded-lg transition-colors"
                >
                  <Trash2 className="h-4 w-4" />
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Bouton "Voir plus" */}
      {hasMoreExpenses && (
        <button
          onClick={() => setShowAll(!showAll)}
          className="w-full py-2 px-4 bg-gray-700 hover:bg-gray-600 rounded-lg transition-colors flex items-center justify-center gap-2"
        >
          {showAll ? (
            <>
              <ChevronUp className="h-4 w-4" />
              Voir moins
            </>
          ) : (
            <>
              <ChevronDown className="h-4 w-4" />
              Voir plus ({filteredExpenses.length - 5} dépenses)
            </>
          )}
        </button>
      )}
    </div>
  );
}

export default ExpensesList;