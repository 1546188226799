import { create } from 'zustand';
import { supabase } from '../lib/supabase';

interface SalesDataPoint {
  date: string;
  revenue: number;
}

interface AdminStats {
  totalUsers: number;
  activeUsers: number;
  totalProducts: number;
  totalSales: number;
  totalRevenue: number;
  averageMargin: number;
  lastMonthUsers: number;
  lastMonthSales: number;
  productsByCategory: Record<string, number>;
  salesData: SalesDataPoint[];
}

interface AdminStore {
  isAdmin: boolean;
  stats: AdminStats;
  checkAdminStatus: () => Promise<void>;
  fetchAdminStats: () => Promise<void>;
}

export const useAdminStore = create<AdminStore>((set) => ({
  isAdmin: false,
  stats: {
    totalUsers: 0,
    activeUsers: 0,
    totalProducts: 0,
    totalSales: 0,
    totalRevenue: 0,
    averageMargin: 0,
    lastMonthUsers: 0,
    lastMonthSales: 0,
    productsByCategory: {},
    salesData: [],
  },

  checkAdminStatus: async () => {
    const { data: { user } } = await supabase.auth.getUser();
    if (user?.email === 'victorsolliec@gmail.com') {
      set({ isAdmin: true });
    }
  },

  fetchAdminStats: async () => {
    try {
      const { data, error } = await supabase
        .rpc('get_admin_stats');

      if (error) {
        console.error('Error fetching admin stats:', error);
        return;
      }

      set({ stats: data });
    } catch (error) {
      console.error('Error fetching admin stats:', error);
    }
  },
}));