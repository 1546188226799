import { create } from 'zustand';
import { Order } from '../types';
import { supabase } from '../lib/supabase';

interface OrderStore {
  orders: Order[];
  isLoading: boolean;
  fetchOrders: () => Promise<void>;
  addOrder: (order: Omit<Order, 'id' | 'userId'>) => Promise<void>;
  updateOrderStatus: (id: string, status: 'pending' | 'ordered') => Promise<void>;
  deleteOrder: (id: string) => Promise<void>;
}

export const useOrderStore = create<OrderStore>((set) => ({
  orders: [],
  isLoading: false,

  fetchOrders: async () => {
    try {
      set({ isLoading: true });
      const { data, error } = await supabase
        .from('orders')
        .select('*')
        .order('purchase_date', { ascending: false });

      if (error) throw error;

      const formattedData = data?.map(order => ({
        id: order.id,
        name: order.name,
        brand: order.brand,
        purchasePrice: order.purchase_price,
        purchaseDate: order.purchase_date,
        imageUrl: order.image_url,
        category: order.category,
        description: order.description,
        status: order.status,
        sellerName: order.seller_name,
        sellerPhone: order.seller_phone,
        sellerEmail: order.seller_email,
        userId: order.user_id,
      }));

      set({ orders: formattedData || [] });
    } catch (error) {
      console.error('Error fetching orders:', error);
      throw error;
    } finally {
      set({ isLoading: false });
    }
  },

  addOrder: async (order) => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('User not authenticated');

      const dbOrder = {
        name: order.name,
        brand: order.brand,
        purchase_price: order.purchasePrice,
        purchase_date: order.purchaseDate,
        image_url: order.imageUrl,
        category: order.category,
        description: order.description,
        status: order.status,
        seller_name: order.sellerName,
        seller_phone: order.sellerPhone,
        seller_email: order.sellerEmail,
        user_id: user.id
      };

      const { data, error } = await supabase
        .from('orders')
        .insert([dbOrder])
        .select()
        .single();

      if (error) throw error;

      const formattedOrder = {
        id: data.id,
        name: data.name,
        brand: data.brand,
        purchasePrice: data.purchase_price,
        purchaseDate: data.purchase_date,
        imageUrl: data.image_url,
        category: data.category,
        description: data.description,
        status: data.status,
        sellerName: data.seller_name,
        sellerPhone: data.seller_phone,
        sellerEmail: data.seller_email,
        userId: data.user_id,
      };

      set(state => ({
        orders: [formattedOrder, ...state.orders]
      }));
    } catch (error) {
      console.error('Error adding order:', error);
      throw error;
    }
  },

  updateOrderStatus: async (id, status) => {
    try {
      const { data, error } = await supabase
        .from('orders')
        .update({ status })
        .eq('id', id)
        .select()
        .single();

      if (error) throw error;

      const formattedOrder = {
        id: data.id,
        name: data.name,
        brand: data.brand,
        purchasePrice: data.purchase_price,
        purchaseDate: data.purchase_date,
        imageUrl: data.image_url,
        category: data.category,
        description: data.description,
        status: data.status,
        sellerName: data.seller_name,
        sellerPhone: data.seller_phone,
        sellerEmail: data.seller_email,
        userId: data.user_id,
      };

      set(state => ({
        orders: state.orders.map(order =>
          order.id === id ? formattedOrder : order
        )
      }));
    } catch (error) {
      console.error('Error updating order status:', error);
      throw error;
    }
  },

  deleteOrder: async (id) => {
    try {
      const { error } = await supabase
        .from('orders')
        .delete()
        .eq('id', id);

      if (error) throw error;

      set(state => ({
        orders: state.orders.filter(order => order.id !== id)
      }));
    } catch (error) {
      console.error('Error deleting order:', error);
      throw error;
    }
  },
}));