import { loadStripe } from '@stripe/stripe-js';
import { supabase } from './supabase';

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY);
const price_id = import.meta.env.VITE_STRIPE_PRICE_ID;

export const redirectToCheckout = async () => {
  try {
    const { data: { session } } = await supabase.auth.getSession();
    if (!session) throw new Error('Non authentifié');

   const response = await fetch(`https://tiuiecftvzbyikpuodan.supabase.co/functions/v1/create-checkout?price_id=${price_id}`, {
     method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${session.access_token}`,
      },
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'Erreur lors de la création de la session');
    }

    const { url } = await response.json();
    if (!url) throw new Error('URL de paiement non disponible');

    // Redirection vers Stripe
    window.location.assign(url);
  } catch (error) {
    console.error('Erreur de redirection vers le paiement:', error);
    throw error;
  }
};

export const redirectToCustomerPortal = async () => {
  try {
    const { data: { session } } = await supabase.auth.getSession();
    if (!session) throw new Error('Non authentifié');

    const response = await fetch('https://tiuiecftvzbyikpuodan.supabase.co/functions/v1/create-portal-session', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${session.access_token}`,
      },
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'Erreur lors de la création de la session');
    }

    const { url } = await response.json();
    if (!url) throw new Error('URL du portail non disponible');

    // Redirection vers le portail client
    window.location.assign(url);
  } catch (error) {
    console.error('Erreur de redirection vers le portail:', error);
    throw error;
  }
};
