import { create } from 'zustand';
import { supabase } from '../lib/supabase';

export type RecurrencePeriod = 'weekly' | 'monthly' | 'yearly' | null;

export interface Expense {
  id: string;
  amount: number;
  type: 'boost' | 'abonnement' | 'autre';
  description?: string;
  is_recurring: boolean;
  recurrence_period: RecurrencePeriod;
  date: string;
  user_id: string;
}

interface ExpenseStore {
  expenses: Expense[];
  isLoading: boolean;
  fetchExpenses: () => Promise<void>;
  addExpense: (expense: Omit<Expense, 'id' | 'user_id'>) => Promise<void>;
  deleteExpense: (id: string) => Promise<void>;
}

export const useExpenseStore = create<ExpenseStore>((set) => ({
  expenses: [],
  isLoading: false,

  fetchExpenses: async () => {
    try {
      set({ isLoading: true });
      const { data, error } = await supabase
        .from('expenses')
        .select('*')
        .order('date', { ascending: false });

      if (error) throw error;
      set({ expenses: data || [] });
    } catch (error) {
      console.error('Error fetching expenses:', error);
    } finally {
      set({ isLoading: false });
    }
  },

  addExpense: async (expense) => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('User not authenticated');

      const { data, error } = await supabase
        .from('expenses')
        .insert([{
          amount: expense.amount,
          type: expense.type,
          description: expense.description,
          is_recurring: expense.is_recurring,
          recurrence_period: expense.recurrence_period,
          date: expense.date,
          user_id: user.id
        }])
        .select()
        .single();

      if (error) throw error;
      set(state => ({
        expenses: [data, ...state.expenses]
      }));
    } catch (error) {
      console.error('Error adding expense:', error);
      throw error;
    }
  },

  deleteExpense: async (id) => {
    try {
      const { error } = await supabase
        .from('expenses')
        .delete()
        .eq('id', id);

      if (error) throw error;
      set(state => ({
        expenses: state.expenses.filter(expense => expense.id !== id)
      }));
    } catch (error) {
      console.error('Error deleting expense:', error);
      throw error;
    }
  },
}));