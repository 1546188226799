import React from 'react';
import { ArrowLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

function Privacy() {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-gray-900 py-20 px-4">
      <div className="max-w-3xl mx-auto">
        <button 
          onClick={() => navigate('/')}
          className="flex items-center gap-2 text-gray-400 hover:text-white mb-8"
        >
          <ArrowLeft className="h-5 w-5" />
          Retour à l'accueil
        </button>

        <h1 className="text-3xl font-bold mb-8">Politique de confidentialité</h1>

        <div className="space-y-8 text-gray-300">
          <section>
            <h2 className="text-xl font-semibold mb-4">1. Collecte des données</h2>
            <p className="mb-4">
              Nous collectons les informations suivantes pour fournir nos services :
            </p>
            <ul className="list-disc list-inside space-y-2 text-gray-400">
              <li>Informations de compte (email, nom d'utilisateur)</li>
              <li>Données de stock et d'inventaire</li>
              <li>Historique des transactions</li>
              <li>Images des produits</li>
              <li>Données d'utilisation de l'application</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-4">2. Utilisation des données</h2>
            <p className="mb-4">
              Vos données sont utilisées pour :
            </p>
            <ul className="list-disc list-inside space-y-2 text-gray-400">
              <li>Fournir et améliorer nos services</li>
              <li>Personnaliser votre expérience</li>
              <li>Assurer la sécurité de votre compte</li>
              <li>Communiquer avec vous concernant votre compte</li>
              <li>Analyser et optimiser nos services</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-4">3. Protection des données</h2>
            <p className="mb-4">
              Nous mettons en œuvre des mesures de sécurité robustes :
            </p>
            <ul className="list-disc list-inside space-y-2 text-gray-400">
              <li>Chiffrement des données en transit et au repos</li>
              <li>Authentification sécurisée</li>
              <li>Sauvegardes régulières</li>
              <li>Surveillance continue de la sécurité</li>
              <li>Accès restreint aux données personnelles</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-4">4. Vos droits</h2>
            <p className="mb-4">
              Vous disposez des droits suivants concernant vos données :
            </p>
            <ul className="list-disc list-inside space-y-2 text-gray-400">
              <li>Accès à vos données personnelles</li>
              <li>Rectification des données inexactes</li>
              <li>Suppression de vos données</li>
              <li>Opposition au traitement</li>
              <li>Portabilité des données</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-4">5. Cookies</h2>
            <p className="text-gray-400">
              Nous utilisons des cookies essentiels pour le fonctionnement de l'application et des cookies analytiques pour améliorer nos services. Vous pouvez contrôler les cookies via les paramètres de votre navigateur.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-4">6. Contact</h2>
            <p className="text-gray-400">
              Pour toute question concernant notre politique de confidentialité ou pour exercer vos droits, contactez-nous à privacy@stocktor.com
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-4">7. Modifications</h2>
            <p className="text-gray-400">
              Nous nous réservons le droit de modifier cette politique de confidentialité à tout moment. Les modifications entrent en vigueur dès leur publication sur cette page.
            </p>
          </section>
        </div>

        <p className="text-gray-400 mt-8 text-sm">
          Dernière mise à jour : 15 mars 2024
        </p>
      </div>
    </div>
  );
}

export default Privacy;