import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Navigation from "./components/Navigation";
import Dashboard from "./pages/Dashboard";
import Stock from "./pages/Stock";
import Sales from "./pages/Sales";
import Orders from "./pages/Consignment";
import Settings from "./pages/Settings";
import Login from "./pages/Login";
import Landing from "./pages/Landing";
import Logo from "./components/Logo";
import Admin from "./pages/Admin";
import AuthCallback from "./pages/AuthCallback";
import { useAuthStore } from "./stores/authStore";
import { useProductStore } from "./stores/productStore";
import { useOrderStore } from "./stores/orderStore";
import { useAdminStore } from "./stores/adminStore";
import Privacy from "./pages/legal/Privacy";
import Terms from "./pages/legal/Terms";
import GDPR from "./pages/legal/GDPR";
import ResetPassword from "./pages/ResetPassword";
import { useSubscriptionStatus } from "./contexts/SubscriptionContext";

function App() {
  const { isAuthenticated } = useAuthStore();
  const { isAdmin, checkAdminStatus } = useAdminStore();
  const fetchProducts = useProductStore((state) => state.fetchProducts);
  const fetchOrders = useOrderStore((state) => state.fetchOrders);
  const { subscriptionStatus, refreshSubscriptionStatus } =
    useSubscriptionStatus();

  useEffect(() => {
    // Prevent bounce effect on iOS
    document.body.style.overscrollBehavior = "none";

    // Add safe area padding for iOS
    document.documentElement.style.setProperty(
      "--sat",
      "env(safe-area-inset-top)"
    );
    document.documentElement.style.setProperty(
      "--sab",
      "env(safe-area-inset-bottom)"
    );
    document.documentElement.style.setProperty(
      "--sal",
      "env(safe-area-inset-left)"
    );
    document.documentElement.style.setProperty(
      "--sar",
      "env(safe-area-inset-right)"
    );
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      const initializeData = async () => {
        try {
          await checkAdminStatus();
          await Promise.all([
            fetchProducts(),
            fetchOrders(),
            refreshSubscriptionStatus(),
          ]);
        } catch (error) {
          console.error("Initialization error:", error);
        }
      };

      initializeData();
    }
  }, [isAuthenticated, checkAdminStatus, fetchProducts, fetchOrders]);

  if (!isAuthenticated) {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/gdpr" element={<GDPR />} />
          <Route path="/auth/callback" element={<AuthCallback />} />
          <Route path="/" element={<Landing />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </BrowserRouter>
    );
  }

  return (
    <BrowserRouter>
      <div className="min-h-screen bg-gray-900 text-white safe-area-fix">
        {/* Header dynamique */}
        <header
          className={`bg-gray-800 border-b border-gray-700 sticky top-0 z-50 ${
            isAuthenticated ? "compact" : ""
          }`}
        >
          <div className="max-w-7xl mx-auto px-4 h-[calc(4rem+var(--sat))] flex items-center justify-between">
            <Logo />
          </div>
        </header>

        {/* Contenu principal */}
        <div
          className={`max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 ${
            isAuthenticated ? "mt-[3rem]" : "mt-[4rem]"
          } pb-[calc(5rem+var(--sab))]`}
        >
          <Routes>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/stock" element={<Stock />} />
            <Route path="/sales" element={<Sales />} />
            <Route path="/consignment" element={<Orders />} />
            <Route path="/settings" element={<Settings />} />
            {isAdmin && <Route path="/admin" element={<Admin />} />}
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/gdpr" element={<GDPR />} />
            <Route path="/" element={<Navigate to="/dashboard" replace />} />
          </Routes>
        </div>
        <Navigation />
      </div>
    </BrowserRouter>
  );
}

export default App;
