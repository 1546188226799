import React from 'react';
import { ArrowLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

function GDPR() {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-gray-900 py-20 px-4">
      <div className="max-w-3xl mx-auto">
        <button 
          onClick={() => navigate('/')}
          className="flex items-center gap-2 text-gray-400 hover:text-white mb-8"
        >
          <ArrowLeft className="h-5 w-5" />
          Retour à l'accueil
        </button>

        <h1 className="text-3xl font-bold mb-8">Politique RGPD</h1>

        <div className="space-y-8 text-gray-300">
          <section>
            <h2 className="text-xl font-semibold mb-4">1. Responsable du traitement</h2>
            <p className="text-gray-400">
              Stocktor est le responsable du traitement de vos données personnelles. Pour toute question concernant la protection de vos données, contactez notre DPO à dpo@stocktor.com
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-4">2. Base légale des traitements</h2>
            <p className="mb-4">
              Nous traitons vos données sur les bases légales suivantes :
            </p>
            <ul className="list-disc list-inside space-y-2 text-gray-400">
              <li>Exécution du contrat de service</li>
              <li>Consentement pour les communications marketing</li>
              <li>Obligations légales (comptabilité, fiscalité)</li>
              <li>Intérêt légitime (amélioration des services)</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-4">3. Données collectées</h2>
            <div className="space-y-4">
              <div>
                <h3 className="font-medium mb-2">Données d'identification :</h3>
                <ul className="list-disc list-inside space-y-1 text-gray-400">
                  <li>Nom et prénom</li>
                  <li>Adresse email</li>
                  <li>Numéro de téléphone (optionnel)</li>
                </ul>
              </div>
              <div>
                <h3 className="font-medium mb-2">Données de facturation :</h3>
                <ul className="list-disc list-inside space-y-1 text-gray-400">
                  <li>Informations de paiement</li>
                  <li>Historique des transactions</li>
                </ul>
              </div>
              <div>
                <h3 className="font-medium mb-2">Données d'utilisation :</h3>
                <ul className="list-disc list-inside space-y-1 text-gray-400">
                  <li>Logs de connexion</li>
                  <li>Actions dans l'application</li>
                  <li>Préférences utilisateur</li>
                </ul>
              </div>
            </div>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-4">4. Durée de conservation</h2>
            <ul className="list-disc list-inside space-y-2 text-gray-400">
              <li>Données de compte : durée de l'abonnement + 3 ans</li>
              <li>Données de facturation : 10 ans (obligation légale)</li>
              <li>Logs de connexion : 1 an</li>
              <li>Données marketing : jusqu'au retrait du consentement</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-4">5. Vos droits RGPD</h2>
            <div className="space-y-4">
              <p className="mb-2">
                Conformément au RGPD, vous disposez des droits suivants :
              </p>
              <ul className="list-disc list-inside space-y-2 text-gray-400">
                <li>Droit d'accès à vos données</li>
                <li>Droit de rectification des données inexactes</li>
                <li>Droit à l'effacement ("droit à l'oubli")</li>
                <li>Droit à la limitation du traitement</li>
                <li>Droit à la portabilité des données</li>
                <li>Droit d'opposition au traitement</li>
                <li>Droit de retirer votre consentement</li>
              </ul>
              <p className="text-gray-400">
                Pour exercer ces droits, contactez-nous à privacy@stocktor.com. Nous répondrons à votre demande dans un délai maximum d'un mois.
              </p>
            </div>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-4">6. Transferts de données</h2>
            <p className="text-gray-400">
              Vos données sont hébergées dans l'Union Européenne. Si un transfert hors UE est nécessaire, nous nous assurons que des garanties appropriées sont en place conformément au RGPD.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-4">7. Sécurité</h2>
            <p className="mb-4">
              Nous mettons en œuvre les mesures suivantes :
            </p>
            <ul className="list-disc list-inside space-y-2 text-gray-400">
              <li>Chiffrement des données</li>
              <li>Authentification forte</li>
              <li>Contrôle des accès</li>
              <li>Surveillance continue</li>
              <li>Formation du personnel</li>
              <li>Audits réguliers</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-4">8. Réclamations</h2>
            <p className="text-gray-400">
              Si vous estimez que le traitement de vos données n'est pas conforme au RGPD, vous pouvez introduire une réclamation auprès de la CNIL (www.cnil.fr).
            </p>
          </section>
        </div>

        <p className="text-gray-400 mt-8 text-sm">
          Dernière mise à jour : 15 mars 2024
        </p>
      </div>
    </div>
  );
}

export default GDPR;