import React, { useState, useEffect } from "react";
import { useAuthStore } from "../stores/authStore";
import {
  Lock,
  Mail,
  ShoppingBag,
  AlertCircle,
  CheckCircle,
  ArrowLeft,
} from "lucide-react";
import { useNavigate, useLocation } from "react-router-dom";
import { supabase } from "../lib/supabase";
import { tr } from "date-fns/locale";
import { Loader2 } from "lucide-react";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const [isSignup, setIsSignup] = useState(false);
  const [isResetPassword, setIsResetPassword] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const { login, signup } = useAuthStore();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.state?.isSignup) {
      setIsSignup(true);
    }
  }, [location]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);

    try {
      if (isSignup) {
        await signup(email, password);
        setSuccess(
          "Votre compte a été créé avec succès ! Vous pouvez maintenant vous connecter."
        );
        setEmail("");
        setPassword("");
        setIsSignup(false);
      } else {
        await login(email, password);
        navigate("/dashboard");
      }
    } catch (error: any) {
      console.error("Auth error:", error);
      setError(error.message || "Une erreur est survenue");
    }
  };

  const handleResetPassword = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    setError(null);
    setSuccess(null);

    try {
      const { error: resetError } = await supabase.auth.resetPasswordForEmail(
        email,
        {
          redirectTo: `${window.location.origin}/reset-password`, // Lien de redirection après réinitialisation
        }
      );

      if (resetError) throw resetError;

      setSuccess(
        "Un email de réinitialisation a été envoyé. Veuillez vérifier votre boîte de réception."
      );
      setEmail("");
      setIsResetPassword(false);
      setLoading(false);
    } catch (error: any) {
      console.error("Reset password error:", error);
      setError(error.message || "Une erreur est survenue");
    }
  };

  return (
    <div className="min-h-screen bg-gray-900 flex items-center justify-center px-4 safe-area-fix">
      <div className="max-w-md w-full space-y-8">
        {/* Back Button */}
        <button
          onClick={() => navigate(-1)}
          className="flex items-center gap-2 text-gray-400 hover:text-white transition-colors">
          <ArrowLeft className="h-5 w-5" />
          Retour
        </button>

        <div className="text-center">
          <div className="flex justify-center mb-6">
            <div className="bg-red-500 p-4 rounded-2xl">
              <ShoppingBag className="h-12 w-12 text-white" />
            </div>
          </div>
          <h2 className="text-3xl font-extrabold text-white mb-2">Stocktor</h2>
          <p className="text-gray-400">
            {isResetPassword
              ? "Réinitialisez votre mot de passe"
              : isSignup
              ? "Créez votre compte"
              : "Connectez-vous à votre compte"}
          </p>
        </div>

        {error && (
          <div className="bg-red-500/10 border border-red-500/20 rounded-lg p-3 flex items-start gap-2">
            <AlertCircle className="h-5 w-5 text-red-500 flex-shrink-0 mt-0.5" />
            <p className="text-sm text-red-500">{error}</p>
          </div>
        )}

        {success && (
          <div className="bg-green-500/10 border border-green-500/20 rounded-lg p-3 flex items-start gap-2">
            <CheckCircle className="h-5 w-5 text-green-500 flex-shrink-0 mt-0.5" />
            <p className="text-sm text-green-500">{success}</p>
          </div>
        )}

        {!isResetPassword ? (
          <form
            className="mt-8 space-y-6 bg-gray-800 p-6 rounded-xl"
            onSubmit={handleSubmit}>
            <div className="space-y-4">
              <div>
                <label className="text-sm font-medium text-gray-300 mb-1 block">
                  Email
                </label>
                <div className="relative">
                  <Mail className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
                  <input
                    type="email"
                    required
                    className="appearance-none block w-full pl-10 pr-3 py-2 border border-gray-700 rounded-lg bg-gray-700 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-transparent"
                    placeholder="Votre email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>

              <div>
                <label className="text-sm font-medium text-gray-300 mb-1 block">
                  Mot de passe
                </label>
                <div className="relative">
                  <Lock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
                  <input
                    type="password"
                    required
                    className="appearance-none block w-full pl-10 pr-3 py-2 border border-gray-700 rounded-lg bg-gray-700 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-transparent"
                    placeholder="Votre mot de passe"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="group relative w-full flex justify-center py-3 px-4 border border-transparent text-sm font-medium rounded-lg text-white bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 transition-colors duration-200">
                {isSignup ? "Créer un compte" : "Se connecter"}
              </button>
            </div>

            {!isSignup && (
              <div className="text-center">
                <button
                  onClick={() => setIsResetPassword(true)}
                  className="text-red-500 hover:text-red-400 font-medium text-center text-sm text-gray-400">
                  Mot de passe oublié ?
                </button>
              </div>
            )}
          </form>
        ) : (
          <form
            className="mt-8 space-y-6 bg-gray-800 p-6 rounded-xl"
            onSubmit={handleResetPassword}>
            <div className="space-y-4">
              <div>
                <label className="text-sm font-medium text-gray-300 mb-1 block">
                  Entrez votre email
                </label>
                <div className="relative">
                  <Mail className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
                  <input
                    type="email"
                    required
                    className="appearance-none block w-full pl-10 pr-3 py-2 border border-gray-700 rounded-lg bg-gray-700 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-transparent"
                    placeholder="Votre email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="group relative w-full flex justify-center py-3 px-4 border border-transparent text-sm font-medium rounded-lg text-white bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 transition-colors duration-200">
                {loading ? (
                  <Loader2 className="h-8 w-8 animate-spin" />
                ) : (
                  "Envoyer le lien de réinitialisation"
                )}
              </button>
            </div>

            <div className="text-center">
              <button
                onClick={() => setIsResetPassword(false)}
                type="button"
                className="text-gray-400 hover:text-white font-medium text-sm">
                Retour à la connexion
              </button>
            </div>
          </form>
        )}

        <div className="text-center text-sm text-gray-400">
          {isSignup ? (
            <>
              Déjà un compte ?{" "}
              <button
                onClick={() => setIsSignup(false)}
                className="text-red-500 hover:text-red-400 font-medium">
                Se connecter
              </button>
            </>
          ) : (
            <>
              Pas encore de compte ?{" "}
              <button
                onClick={() => setIsSignup(true)}
                className="text-red-500 hover:text-red-400 font-medium">
                Créer un compte
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Login;
