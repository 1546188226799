import React, { createContext, useContext, useEffect, useState } from "react";
import { supabase } from "../lib/supabase"; // Assurez-vous de configurer supabase correctement

// Définir le type du statut d'abonnement
interface SubscriptionStatus {
  status: String;
  hasTrialBeenUsed: boolean;
  isTrialExpired: boolean;
  subscriptionEndDate: null;
  trialEndDate: null;
  subscriptionLoading: boolean;
  customerId: String;
}

// Valeur initiale par défaut
const initialStatus: SubscriptionStatus = {
  status: "trial",
  hasTrialBeenUsed: false,
  isTrialExpired: true,
  subscriptionEndDate: null,
  trialEndDate: null,
  subscriptionLoading: true,
  customerId: "",
};

// Créer le contexte
const SubscriptionContext = createContext<{
  subscriptionStatus: SubscriptionStatus;
  refreshSubscriptionStatus: () => void; // Option pour forcer la mise à jour
}>({
  subscriptionStatus: initialStatus,
  refreshSubscriptionStatus: () => {},
});

// Fournisseur de contexte
export const SubscriptionProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [subscriptionStatus, setSubscriptionStatus] =
    useState<SubscriptionStatus>(initialStatus);

  const fetchSubscriptionStatus = async () => {
    setSubscriptionStatus((prev) => ({ ...prev, subscriptionLoading: true }));
    try {
      // Fetch the authenticated user
      const {
        data: { user },
        error: userError,
      } = await supabase.auth.getUser();
      if (userError || !user) throw new Error("User not authenticated");

      // Fetch subscription details
      // const { data: subscription, error: subscriptionError } = await supabase
      //   .from("subscriptions")
      //   .select("*")
      //   .eq("user_id", user.id)
      //   .single();

      const {
        data: { session },
      } = await supabase.auth.getSession();
      if (!session) throw new Error("Non authentifié");

      const response = await fetch(
        `https://tiuiecftvzbyikpuodan.supabase.co/functions/v1/get-subscription?userId=${user.id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${session.access_token}`,
          },
        }
      );

      const subscription = await response.json();

      let status = "trial";
      let hasTrialBeenUsed = true;
      let isTrialExpired = true;
      let subscriptionEndDate = null;
      let trialEndDate = null;
      let customerId = null;

      try {
        status = subscription.status;
      } catch (error) {
        console.log(error);
      }
      try {
        hasTrialBeenUsed = subscription.has_trial_been_used;
      } catch (error) {
        console.log(error);
      }
      try {
        isTrialExpired = subscription.trial_end_date
          ? new Date(subscription.trial_end_date) < new Date()
          : false;
      } catch (error) {
        console.log(error);
      }
      try {
        subscriptionEndDate = subscription.subscription_end_date;
      } catch (error) {
        console.log(error);
      }
      try {
        trialEndDate = subscription.trial_end_date;
      } catch (error) {
        console.log(error);
      }
      try {
        customerId = subscription.customer_id;
      } catch (error) {
        console.log(error);
      }

      setSubscriptionStatus({
        status,
        hasTrialBeenUsed,
        isTrialExpired,
        subscriptionEndDate,
        trialEndDate,
        subscriptionLoading: false,
        customerId,
      });
    } catch (error) {
      console.log("Status:", error);
    }
  };

  // Appeler la fonction au premier montage
  useEffect(() => {
    fetchSubscriptionStatus();
  }, []);

  return (
    <SubscriptionContext.Provider
      value={{
        subscriptionStatus,
        refreshSubscriptionStatus: fetchSubscriptionStatus,
      }}>
      {children}
    </SubscriptionContext.Provider>
  );
};

// Hook pour consommer le contexte
export const useSubscriptionStatus = () => useContext(SubscriptionContext);
