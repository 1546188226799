import { create } from "zustand";
import { persist } from "zustand/middleware";
import { supabase } from "../lib/supabase";
import { redirectToCheckout } from "../lib/stripe";

interface SubscriptionStore {
  status: "inactive" | "trial" | "active" | "canceled";
  trialEndDate: string | null;
  hasTrialBeenUsed: boolean;
  subscriptionEndDate: string | null;
  stripeCustomerId: string | null;
  stripeSubscriptionId: string | null;
  startTrial: () => Promise<void>;
  subscribe: () => Promise<void>;
  checkSubscriptionStatus: () => Promise<void>;
  reset: () => void;
}

export const useSubscriptionStore = create<SubscriptionStore>()(
  persist(
    (set) => ({
      status: "inactive",
      trialEndDate: null,
      hasTrialBeenUsed: false,
      subscriptionEndDate: null,
      stripeCustomerId: null,
      stripeSubscriptionId: null,

      startTrial: async () => {

        const {
          data: { user },
        } = await supabase.auth.getUser();
        if (!user) throw new Error("Non authentifié");

        const trialEndDate = new Date();
        trialEndDate.setDate(trialEndDate.getDate() + 3);

        const body = {
          userId: user.id,
          trialEndDate: trialEndDate,
        };

        const response = await fetch(
          "https://tiuiecftvzbyikpuodan.supabase.co/functions/v1/update-subscription?action=START_TRIAL",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
          }
        );

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(
            errorData.error || "Erreur lors de la création de la session"
          );
        }
      },

      subscribe: async () => {
        try {
          await redirectToCheckout();
        } catch (error) {
          console.error("Erreur lors de l'abonnement:", error);
          throw error;
        }
      },

      checkSubscriptionStatus: async () => {
        try {
          const {
            data: { user },
          } = await supabase.auth.getUser();
          if (!user) return;

          const { data, error } = await supabase
            .from("subscriptions")
            .select("*")
            .eq("user_id", user.id)
            .single();

          if (error) throw error;

          if (data) {
            // Vérifier si l'essai est expiré
            if (data.status === "trial" && data.trial_end_date) {
              const trialEnd = new Date(data.trial_end_date);
              if (trialEnd < new Date()) {
                // Mettre à jour le statut en inactif si l'essai est expiré
                await supabase
                  .from("subscriptions")
                  .update({
                    status: "inactive",
                  })
                  .eq("user_id", user.id);

                set({
                  status: "inactive",
                  trialEndDate: null,
                  hasTrialBeenUsed: true,
                });
                return;
              }
            }

            // Vérifier si l'abonnement est expiré
            if (data.status === "active" && data.subscription_end_date) {
              const subscriptionEnd = new Date(data.subscription_end_date);
              if (subscriptionEnd < new Date()) {
                await supabase
                  .from("subscriptions")
                  .update({
                    status: "inactive",
                  })
                  .eq("user_id", user.id);

                set({
                  status: "inactive",
                  subscriptionEndDate: null,
                  stripeCustomerId: null,
                  stripeSubscriptionId: null,
                });
                return;
              }
            }

            // Mettre à jour le state avec les données actuelles
            set({
              status: data.status,
              trialEndDate: data.trial_end_date,
              hasTrialBeenUsed: data.has_trial_been_used,
              subscriptionEndDate: data.subscription_end_date,
              stripeCustomerId: data.stripe_customer_id,
              stripeSubscriptionId: data.stripe_subscription_id,
            });
          }
        } catch (error) {
          console.error("Erreur lors de la vérification du statut:", error);
        }
      },

      reset: () => {
        set({
          status: "inactive",
          trialEndDate: null,
          hasTrialBeenUsed: false,
          subscriptionEndDate: null,
          stripeCustomerId: null,
          stripeSubscriptionId: null,
        });
      },
    }),
    {
      name: "subscription-storage",
      partialize: (state) => ({
        status: state.status,
        trialEndDate: state.trialEndDate,
        hasTrialBeenUsed: state.hasTrialBeenUsed,
        subscriptionEndDate: state.subscriptionEndDate,
        stripeCustomerId: state.stripeCustomerId,
        stripeSubscriptionId: state.stripeSubscriptionId,
      }),
    }
  )
);
