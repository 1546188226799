import React, { useState, useEffect } from "react";
import { useProductStore } from "../stores/productStore";
import { useCompanyStore } from "../stores/companyStore";
import { useExpenseStore } from "../stores/expenseStore";
import { useTranslation } from "react-i18next";
import MonthYearPicker from "../components/MonthYearPicker";
import StatsCard from "../components/StatsCard";
import ExpensesList from "../components/ExpensesList";
import AddExpenseModal from "../components/AddExpenseModal";
import {
  DollarSign,
  TrendingUp,
  ShoppingCart,
  Percent,
  Target,
  Building2,
  Info,
  Plus,
} from "lucide-react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import { SubscriptionEndedComponent } from "../components/SubscriptionComponent";
import { useSubscriptionStatus } from "../contexts/SubscriptionContext";

function Dashboard() {
  const target = 1000;
  const months = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ];

  const companyTypes = {
    micro: "Micro-entreprise",
    eurl: "EURL",
    sasu: "SASU",
    sarl: "SARL",
  };
  
  const products = useProductStore((state) => state.products);
  const { expenses, addExpense, deleteExpense, fetchExpenses } =
    useExpenseStore();
  const { type, microStatus, calculateCharges } = useCompanyStore();
  const { t } = useTranslation();
  const [selectedMonth, setSelectedMonth] = useState<string | null>(null);
  const [selectedYear, setSelectedYear] = useState<number>(
    new Date().getFullYear()
  );
  const [isExpenseModalOpen, setIsExpenseModalOpen] = useState(false);

  const { subscriptionStatus, refreshSubscriptionStatus } =
    useSubscriptionStatus();
  const { status, isTrialExpired, subscriptionLoading } = subscriptionStatus;

  useEffect(() => {
    fetchExpenses();
  }, [fetchExpenses]);

  // Filtrer les produits et dépenses en fonction du mois et de l'année sélectionnés
  const filteredProducts = products.filter((product) => {
    if (!selectedMonth && !selectedYear) return true;
    const productDate = new Date(
      product.status === "sold" ? product.saleDate! : product.purchaseDate
    );
    const monthMatch =
      !selectedMonth || months[productDate.getMonth()] === selectedMonth;
    const yearMatch = productDate.getFullYear() === selectedYear;
    return monthMatch && yearMatch;
  });

  const filteredExpenses = expenses.filter((expense) => {
    if (!selectedMonth && !selectedYear) return true;
    const expenseDate = new Date(expense.date);
    const monthMatch =
      !selectedMonth || months[expenseDate.getMonth()] === selectedMonth;
    const yearMatch = expenseDate.getFullYear() === selectedYear;
    return monthMatch && yearMatch;
  });

  // Calcul des statistiques avec les produits filtrés
  const soldProducts = filteredProducts.filter(
    (product) => product.status === "sold"
  );
  const totalSales = Math.round(
    soldProducts.reduce((sum, product) => sum + (product.salePrice || 0), 0)
  );
  const totalProductPurchases = Math.round(
    filteredProducts.reduce((sum, product) => sum + product.purchasePrice, 0)
  );
  const totalExpenses = Math.round(
    filteredExpenses.reduce((sum, expense) => sum + expense.amount, 0)
  );
  const totalPurchases = totalProductPurchases + totalExpenses;
  const grossProfit = totalSales - totalPurchases;

  // Calcul des charges basé sur le bénéfice brut
  const charges = calculateCharges(totalSales, totalPurchases);

  // Données pour le graphique
  const salesData = soldProducts
    .reduce((acc, product) => {
      const date = new Date(product.saleDate!).toISOString().split("T")[0];
      const existing = acc.find((d) => d.date === date);
      if (existing) {
        existing.revenue += product.salePrice || 0;
      } else {
        acc.push({ date, revenue: product.salePrice || 0 });
      }
      return acc;
    }, [] as { date: string; revenue: number }[])
    .sort((a, b) => a.date.localeCompare(b.date));

  return (
    <div className="pt-6 space-y-6 pb-20">
      <h1 className="text-2xl font-bold text-center">{t("dashboard.title")}</h1>
      {status == "active" || (status == "trial" && !isTrialExpired) ? (
        <>
          <MonthYearPicker
            selectedMonth={selectedMonth}
            selectedYear={selectedYear}
            onMonthChange={setSelectedMonth}
            onYearChange={setSelectedYear}
            onReset={() => {
              setSelectedMonth(null);
              setSelectedYear(new Date().getFullYear());
            }}
          />

          {/* Company Type Info */}
          {type && (
            <div className="bg-gray-800 p-4 rounded-lg">
              <div className="flex items-center gap-2 mb-4">
                <Building2 className="h-5 w-5 text-red-500" />
                <h2 className="font-semibold">
                  {companyTypes[type]}{" "}
                  {type === "micro" &&
                    `(${microStatus === "acre" ? "Avec ACRE" : "Sans ACRE"})`}
                </h2>
              </div>
            </div>
          )}

          <div className="grid grid-cols-2 gap-4">
            <StatsCard
              title="Bénéfice brut"
              value={`${grossProfit}€`}
              icon={<DollarSign className="h-6 w-6" />}
            />
            <StatsCard
              title={t("dashboard.stats.totalPurchases")}
              value={`${totalPurchases}€`}
              icon={<ShoppingCart className="h-6 w-6" />}
            />
            <StatsCard
              title="Chiffre d'affaires"
              value={`${totalSales}€`}
              icon={<TrendingUp className="h-6 w-6" />}
            />
            <StatsCard
              title="Bénéfice net"
              value={`${Math.round(charges.netRevenue)}€`}
              icon={<DollarSign className="h-6 w-6" />}
            />
          </div>

          {/* Revenue Chart */}
          <div className="bg-gray-800 p-4 rounded-lg">
            <h3 className="text-lg font-semibold mb-4">
              Évolution du chiffre d'affaires
            </h3>
            <div className="h-[300px]">
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={salesData}>
                  <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
                  <XAxis
                    dataKey="date"
                    stroke="#9CA3AF"
                    tickFormatter={(date) =>
                      format(new Date(date), "dd MMM", { locale: fr })
                    }
                  />
                  <YAxis
                    stroke="#9CA3AF"
                    tickFormatter={(value) => `${value}€`}
                  />
                  <Tooltip
                    contentStyle={{
                      backgroundColor: "#1F2937",
                      border: "none",
                      borderRadius: "0.5rem",
                      color: "#F3F4F6",
                    }}
                    formatter={(value: number) => [
                      `${value}€`,
                      "Chiffre d'affaires",
                    ]}
                    labelFormatter={(date) =>
                      format(new Date(date), "dd MMMM yyyy", { locale: fr })
                    }
                  />
                  <Line
                    type="monotone"
                    dataKey="revenue"
                    stroke="#EF4444"
                    strokeWidth={2}
                    dot={false}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>

          {/* Expenses Section */}
          <div className="bg-gray-800 p-4 rounded-lg">
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center gap-2">
                <ShoppingCart className="h-5 w-5 text-red-500" />
                <h3 className="text-lg font-semibold">Dépenses</h3>
              </div>
              <div className="flex items-center gap-2">
                <span className="text-sm text-gray-400">
                  Total:{" "}
                  <span className="text-white font-medium">
                    {Math.round(totalExpenses)}€
                  </span>
                </span>
                <button
                  onClick={() => setIsExpenseModalOpen(true)}
                  className="bg-red-500 p-2 rounded-lg hover:bg-red-600 transition-colors">
                  <Plus className="h-5 w-5" />
                </button>
              </div>
            </div>

            <ExpensesList
              expenses={filteredExpenses}
              onDelete={deleteExpense}
            />
          </div>

          {/* Charges Section */}
          {type && (
            <div className="bg-gray-800 p-4 rounded-lg">
              <h3 className="text-lg font-semibold mb-4">Charges à payer</h3>
              <div className="space-y-4">
                {charges.details.map((charge, index) => (
                  <div
                    key={index}
                    className="p-3 bg-gray-700/50 rounded-lg space-y-1">
                    <div className="flex justify-between items-center">
                      <div className="flex items-center gap-2">
                        <span className="font-medium">{charge.label}</span>
                        {charge.info && (
                          <div className="group relative">
                            <Info className="h-4 w-4 text-gray-400" />
                            <div className="absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 hidden group-hover:block w-64 p-2 bg-gray-900 rounded-lg text-sm">
                              {charge.info}
                            </div>
                          </div>
                        )}
                      </div>
                      <span className="font-medium">
                        {Math.round(charge.amount)}€
                      </span>
                    </div>
                    {charge.percentage > 0 && charge.baseAmount && (
                      <div className="text-sm text-gray-400">
                        {charge.percentage}% de {Math.round(charge.baseAmount)}€
                      </div>
                    )}
                  </div>
                ))}
                <div className="flex justify-between items-center p-3 bg-red-500/10 text-red-500 rounded-lg font-medium">
                  <span>Total des charges</span>
                  <span>{Math.round(charges.total)}€</span>
                </div>
                <div
                  className={`flex justify-between items-center p-3 rounded-lg font-medium ${
                    charges.netRevenue >= 0
                      ? "bg-green-500/10 text-green-500"
                      : "bg-red-500/10 text-red-500"
                  }`}>
                  <span>Bénéfice total</span>
                  <span>{Math.round(charges.netRevenue)}€</span>
                </div>
              </div>
            </div>
          )}

          {/* Target Progress */}
          <div className="bg-gray-800 p-4 rounded-lg">
            <div className="flex items-center justify-between mb-2">
              <h3 className="text-lg font-semibold flex items-center gap-2">
                <Target className="h-5 w-5" />
                {t("dashboard.target")} ({target}€)
              </h3>
              <span className="text-sm text-gray-400">
                {Math.round((grossProfit / target) * 100)}%
              </span>
            </div>
            <div className="h-2 bg-gray-700 rounded-full">
              <div
                className="h-full bg-red-500 rounded-full transition-all duration-500"
                style={{
                  width: `${Math.min((grossProfit / target) * 100, 100)}%`,
                }}
              />
            </div>
          </div>

          <AddExpenseModal
            isOpen={isExpenseModalOpen}
            onClose={() => setIsExpenseModalOpen(false)}
            onSubmit={addExpense}
          />
        </>
      ) : (
        <SubscriptionEndedComponent />
      )}
    </div>
  );
}

export default Dashboard;
