import React from 'react';
import { ArrowLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

function Terms() {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-gray-900 py-20 px-4">
      <div className="max-w-3xl mx-auto">
        <button 
          onClick={() => navigate('/')}
          className="flex items-center gap-2 text-gray-400 hover:text-white mb-8"
        >
          <ArrowLeft className="h-5 w-5" />
          Retour à l'accueil
        </button>

        <h1 className="text-3xl font-bold mb-8">Conditions Générales d'Utilisation</h1>

        <div className="space-y-8 text-gray-300">
          <section>
            <h2 className="text-xl font-semibold mb-4">1. Objet</h2>
            <p className="text-gray-400">
              Les présentes CGU régissent l'utilisation de l'application Stocktor, une solution de gestion de stock et de suivi des ventes. En utilisant notre service, vous acceptez d'être lié par ces conditions.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-4">2. Description du service</h2>
            <p className="mb-4">
              Stocktor propose les services suivants :
            </p>
            <ul className="list-disc list-inside space-y-2 text-gray-400">
              <li>Gestion d'inventaire</li>
              <li>Suivi des ventes</li>
              <li>Analyse des profits</li>
              <li>Gestion des commandes</li>
              <li>Stockage des données dans le cloud</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-4">3. Abonnement et paiement</h2>
            <ul className="list-disc list-inside space-y-2 text-gray-400">
              <li>Essai gratuit de 3 jours sans engagement</li>
              <li>Abonnement mensuel de 14.99€ après la période d'essai</li>
              <li>Paiement sécurisé par carte bancaire</li>
              <li>Facturation automatique chaque mois</li>
              <li>Annulation possible à tout moment</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-4">4. Obligations de l'utilisateur</h2>
            <p className="mb-4">
              En utilisant Stocktor, vous vous engagez à :
            </p>
            <ul className="list-disc list-inside space-y-2 text-gray-400">
              <li>Fournir des informations exactes et à jour</li>
              <li>Protéger vos identifiants de connexion</li>
              <li>Respecter les droits de propriété intellectuelle</li>
              <li>Ne pas utiliser le service à des fins illégales</li>
              <li>Ne pas tenter de compromettre la sécurité du service</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-4">5. Propriété intellectuelle</h2>
            <p className="text-gray-400">
              Tous les droits de propriété intellectuelle relatifs à Stocktor (logiciel, design, logos, marques) sont la propriété exclusive de Stocktor. Aucune utilisation non autorisée n'est permise.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-4">6. Limitation de responsabilité</h2>
            <ul className="list-disc list-inside space-y-2 text-gray-400">
              <li>Service fourni "tel quel" sans garantie</li>
              <li>Non responsabilité en cas de perte de données</li>
              <li>Maintenance et mises à jour possibles</li>
              <li>Force majeure</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-4">7. Résiliation</h2>
            <p className="text-gray-400">
              Stocktor se réserve le droit de suspendre ou résilier votre compte en cas de violation des CGU. Vous pouvez également résilier votre compte à tout moment via les paramètres.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-4">8. Modifications</h2>
            <p className="text-gray-400">
              Nous nous réservons le droit de modifier ces CGU à tout moment. Les utilisateurs seront notifiés des changements importants par email.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-4">9. Loi applicable</h2>
            <p className="text-gray-400">
              Ces CGU sont régies par le droit français. Tout litige relève de la compétence exclusive des tribunaux français.
            </p>
          </section>
        </div>

        <p className="text-gray-400 mt-8 text-sm">
          Dernière mise à jour : 15 mars 2024
        </p>
      </div>
    </div>
  );
}

export default Terms;