import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export type CompanyType = 'micro' | 'eurl' | 'sasu' | 'sarl' | null;
export type MicroStatus = 'acre' | 'normal' | null;

interface ChargeDetails {
  label: string;
  amount: number;
  percentage: number;
  baseAmount?: number;
  info?: string;
}

interface ChargeCalculation {
  urssaf: number;
  tva: number;
  tvaNette: number;
  is: number;
  total: number;
  netRevenue: number;
  details: ChargeDetails[];
}

interface CompanyState {
  type: CompanyType;
  microStatus: MicroStatus;
  setType: (type: CompanyType) => void;
  setMicroStatus: (status: MicroStatus) => void;
  calculateCharges: (revenue: number, expenses: number) => ChargeCalculation;
}

export const useCompanyStore = create<CompanyState>()(
  persist(
    (set, get) => ({
      type: null,
      microStatus: null,

      setType: (type) => set({ type }),
      setMicroStatus: (status) => set({ microStatus: status }),

      calculateCharges: (revenue: number, expenses: number) => {
        try {
          const { type, microStatus } = get();
          const grossProfit = revenue - expenses;
          
          const charges: ChargeCalculation = {
            urssaf: 0,
            tva: 0,
            tvaNette: 0,
            is: 0,
            total: 0,
            netRevenue: 0,
            details: []
          };

          if (type === 'micro') {
            const baseRate = microStatus === 'acre' ? 0.064 : 0.123;
            charges.urssaf = revenue * baseRate;
            charges.details.push({
              label: 'URSSAF',
              amount: charges.urssaf,
              percentage: baseRate * 100,
              baseAmount: revenue,
              info: microStatus === 'acre' ? 'Taux ACRE de 6.4%' : 'Taux normal de 12.3%'
            });

            if (revenue > 85800) {
              charges.tva = revenue * 0.20;
              charges.details.push({
                label: 'TVA',
                amount: charges.tva,
                percentage: 20,
                baseAmount: revenue,
                info: 'TVA applicable au-delà de 85 800€ de CA'
              });
            }
          } else if (type === 'eurl' || type === 'sarl') {
            charges.urssaf = grossProfit * 0.45;
            charges.details.push({
              label: 'Charges sociales TNS',
              amount: charges.urssaf,
              percentage: 45,
              baseAmount: grossProfit,
              info: 'Calculé sur le bénéfice'
            });

            charges.tva = revenue * 0.20;
            const tvaDéductible = expenses * 0.20;
            charges.tvaNette = charges.tva - tvaDéductible;

            charges.details.push({
              label: 'TVA Collectée',
              amount: charges.tva,
              percentage: 20,
              baseAmount: revenue,
              info: 'TVA sur les ventes'
            });
            charges.details.push({
              label: 'TVA Déductible',
              amount: -tvaDéductible,
              percentage: 20,
              baseAmount: expenses,
              info: 'TVA sur les achats'
            });
            charges.details.push({
              label: 'TVA Nette à payer',
              amount: charges.tvaNette,
              percentage: 0,
              info: 'Différence entre TVA collectée et déductible'
            });

            const profitAfterSocialCharges = grossProfit - charges.urssaf;
            if (profitAfterSocialCharges <= 38120) {
              charges.is = profitAfterSocialCharges * 0.15;
              charges.details.push({
                label: 'Impôt sur les Sociétés (IS)',
                amount: charges.is,
                percentage: 15,
                baseAmount: profitAfterSocialCharges,
                info: 'Taux réduit jusqu\'à 38 120€'
              });
            } else {
              const isReduit = 38120 * 0.15;
              const isNormal = (profitAfterSocialCharges - 38120) * 0.25;
              charges.is = isReduit + isNormal;
              charges.details.push({
                label: 'IS - Taux réduit',
                amount: isReduit,
                percentage: 15,
                baseAmount: 38120,
                info: 'Sur les premiers 38 120€'
              });
              charges.details.push({
                label: 'IS - Taux normal',
                amount: isNormal,
                percentage: 25,
                baseAmount: profitAfterSocialCharges - 38120,
                info: 'Sur le reste du bénéfice'
              });
            }
          } else if (type === 'sasu') {
            charges.tva = revenue * 0.20;
            const tvaDéductible = expenses * 0.20;
            charges.tvaNette = charges.tva - tvaDéductible;

            charges.details.push({
              label: 'TVA Collectée',
              amount: charges.tva,
              percentage: 20,
              baseAmount: revenue,
              info: 'TVA sur les ventes'
            });
            charges.details.push({
              label: 'TVA Déductible',
              amount: -tvaDéductible,
              percentage: 20,
              baseAmount: expenses,
              info: 'TVA sur les achats'
            });
            charges.details.push({
              label: 'TVA Nette à payer',
              amount: charges.tvaNette,
              percentage: 0,
              info: 'Différence entre TVA collectée et déductible'
            });

            charges.urssaf = grossProfit * 0.40;
            charges.details.push({
              label: 'Charges sociales',
              amount: charges.urssaf,
              percentage: 40,
              baseAmount: grossProfit,
              info: 'Pour un dirigeant rémunéré'
            });

            const profitAfterSocialCharges = grossProfit - charges.urssaf;
            if (profitAfterSocialCharges <= 38120) {
              charges.is = profitAfterSocialCharges * 0.15;
              charges.details.push({
                label: 'IS - Taux réduit',
                amount: charges.is,
                percentage: 15,
                baseAmount: profitAfterSocialCharges,
                info: 'Taux réduit jusqu\'à 38 120€'
              });
            } else {
              const isReduit = 38120 * 0.15;
              const isNormal = (profitAfterSocialCharges - 38120) * 0.25;
              charges.is = isReduit + isNormal;
              charges.details.push({
                label: 'IS - Taux réduit',
                amount: isReduit,
                percentage: 15,
                baseAmount: 38120,
                info: 'Sur les premiers 38 120€'
              });
              charges.details.push({
                label: 'IS - Taux normal',
                amount: isNormal,
                percentage: 25,
                baseAmount: profitAfterSocialCharges - 38120,
                info: 'Sur le reste du bénéfice'
              });
            }
          }

          charges.total = type === 'micro' 
            ? charges.urssaf + charges.tva + charges.is
            : charges.urssaf + charges.tvaNette + charges.is;
          
          charges.netRevenue = grossProfit - charges.total;

          return charges;
        } catch (error) {
          console.error('Error calculating charges:', error);
          return {
            urssaf: 0,
            tva: 0,
            tvaNette: 0,
            is: 0,
            total: 0,
            netRevenue: 0,
            details: []
          };
        }
      }
    }),
    {
      name: 'company-storage',
      partialize: (state) => ({
        type: state.type,
        microStatus: state.microStatus
      })
    }
  )
);