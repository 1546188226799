import React, { useState } from 'react';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { Product } from '../types';
import SellProductModal from './SellProductModal';
import { useTranslation } from 'react-i18next';
import { useProductStore } from '../stores/productStore';
import { Trash2 } from 'lucide-react';

interface ProductListProps {
  products: Product[];
}

function ProductList({ products }: ProductListProps) {
  const [selectedProductId, setSelectedProductId] = useState<string | null>(null);
  const { t } = useTranslation();
  const deleteProduct = useProductStore(state => state.deleteProduct);
  const [isDeleting, setIsDeleting] = useState<string | null>(null);

  const handleDelete = async (id: string) => {
    try {
      setIsDeleting(id);
      await deleteProduct(id);
    } catch (error) {
      console.error('Error deleting product:', error);
    } finally {
      setIsDeleting(null);
    }
  };

  return (
    <>
      <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-3">
        {products.map(product => (
          <div key={product.id} className="bg-gray-800 rounded-lg overflow-hidden">
            <div className="relative">
              <img
                src={product.imageUrl}
                alt={product.name}
                className="w-full aspect-square object-cover"
              />
              <button
                onClick={() => handleDelete(product.id)}
                disabled={isDeleting === product.id}
                className="absolute top-2 right-2 p-1.5 bg-red-500/80 hover:bg-red-500 rounded-lg transition-colors"
              >
                <Trash2 className="h-4 w-4" />
              </button>
            </div>
            <div className="p-3">
              <h3 className="font-semibold text-sm mb-0.5 truncate">{product.name}</h3>
              <p className="text-xs text-gray-400 mb-2 truncate">{product.brand}</p>
              {product.description && (
                <p className="text-xs text-gray-300 mb-2 border-b border-gray-700 pb-2 line-clamp-2">
                  {product.description}
                </p>
              )}
              <div className="space-y-1 text-xs">
                <p className="text-gray-400">
                  {t('product.purchasePrice')}: <span className="text-white">{Math.round(product.purchasePrice)}€</span>
                </p>
                <p className="text-gray-400">
                  {t('product.purchaseDate')}: <span className="text-white">
                    {format(new Date(product.purchaseDate), 'dd/MM/yyyy', { locale: fr })}
                  </span>
                </p>
                <div className={`inline-block px-2 py-0.5 rounded-full text-xs ${
                  product.status === 'in_stock' 
                    ? 'bg-green-500/20 text-green-500' 
                    : 'bg-red-500/20 text-red-500'
                }`}>
                  {product.status === 'in_stock' ? t('product.status.inStock') : t('product.status.sold')}
                </div>
                {product.status === 'sold' && (
                  <p className="text-gray-400">
                    {t('product.salePrice')}: <span className="text-white">{Math.round(product.salePrice || 0)}€</span>
                  </p>
                )}
                {product.status === 'in_stock' && (
                  <button
                    onClick={() => setSelectedProductId(product.id)}
                    className="mt-2 w-full bg-red-500 text-white px-3 py-1.5 rounded-lg text-xs hover:bg-red-600"
                  >
                    {t('product.status.sold')}
                  </button>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>

      <SellProductModal
        isOpen={!!selectedProductId}
        onClose={() => setSelectedProductId(null)}
        productId={selectedProductId || ''}
      />
    </>
  );
}

export default ProductList;