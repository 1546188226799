import { create } from 'zustand';
import { Product } from '../types';
import { supabase } from '../lib/supabase';

interface ProductStore {
  products: Product[];
  isLoading: boolean;
  fetchProducts: () => Promise<void>;
  addProduct: (product: Product) => Promise<void>;
  updateProduct: (id: string, updates: Partial<Product>) => Promise<void>;
  markAsSold: (id: string, salePrice: number, saleDate: string) => Promise<void>;
  deleteProduct: (id: string) => Promise<void>;
}

export const useProductStore = create<ProductStore>((set) => ({
  products: [],
  isLoading: false,

  fetchProducts: async () => {
    try {
      set({ isLoading: true });
      const { data, error } = await supabase
        .from('products')
        .select('*')
        .order('purchase_date', { ascending: false });

      if (error) throw error;

      // Convert snake_case to camelCase
      const formattedData = data?.map(product => ({
        ...product,
        purchaseDate: product.purchase_date,
        purchasePrice: product.purchase_price,
        imageUrl: product.image_url,
        salePrice: product.sale_price,
        saleDate: product.sale_date,
        userId: product.user_id,
      }));

      set({ products: formattedData || [] });
    } catch (error) {
      console.error('Error fetching products:', error);
      throw error;
    } finally {
      set({ isLoading: false });
    }
  },

  addProduct: async (product) => {
    try {
      // Convert camelCase to snake_case for database
      const dbProduct = {
        name: product.name,
        brand: product.brand,
        purchase_price: product.purchasePrice,
        purchase_date: product.purchaseDate,
        image_url: product.imageUrl,
        status: product.status,
        sale_price: product.salePrice,
        sale_date: product.saleDate,
        category: product.category,
        description: product.description,
        user_id: product.user_id,
      };

      const { data, error } = await supabase
        .from('products')
        .insert([dbProduct])
        .select()
        .single();

      if (error) {
        console.error('Error adding product:', error);
        throw error;
      }

      // Convert back to camelCase for state
      const formattedData = {
        ...data,
        purchaseDate: data.purchase_date,
        purchasePrice: data.purchase_price,
        imageUrl: data.image_url,
        salePrice: data.sale_price,
        saleDate: data.sale_date,
        userId: data.user_id,
      };

      set(state => ({
        products: [formattedData, ...state.products]
      }));
    } catch (error) {
      console.error('Error adding product:', error);
      throw error;
    }
  },

  updateProduct: async (id, updates) => {
    try {
      // Convert updates to snake_case
      const dbUpdates = {
        name: updates.name,
        brand: updates.brand,
        purchase_price: updates.purchasePrice,
        purchase_date: updates.purchaseDate,
        image_url: updates.imageUrl,
        status: updates.status,
        sale_price: updates.salePrice,
        sale_date: updates.saleDate,
        category: updates.category,
        description: updates.description,
      };

      const { data, error } = await supabase
        .from('products')
        .update(dbUpdates)
        .eq('id', id)
        .select()
        .single();

      if (error) throw error;

      // Convert back to camelCase
      const formattedData = {
        ...data,
        purchaseDate: data.purchase_date,
        purchasePrice: data.purchase_price,
        imageUrl: data.image_url,
        salePrice: data.sale_price,
        saleDate: data.sale_date,
        userId: data.user_id,
      };

      set(state => ({
        products: state.products.map(product =>
          product.id === id ? formattedData : product
        )
      }));
    } catch (error) {
      console.error('Error updating product:', error);
      throw error;
    }
  },

  markAsSold: async (id, salePrice, saleDate) => {
    try {
      const { data, error } = await supabase
        .from('products')
        .update({
          status: 'sold',
          sale_price: salePrice,
          sale_date: saleDate
        })
        .eq('id', id)
        .select()
        .single();

      if (error) throw error;

      // Convert to camelCase
      const formattedData = {
        ...data,
        purchaseDate: data.purchase_date,
        purchasePrice: data.purchase_price,
        imageUrl: data.image_url,
        salePrice: data.sale_price,
        saleDate: data.sale_date,
        userId: data.user_id,
      };

      set(state => ({
        products: state.products.map(product =>
          product.id === id ? formattedData : product
        )
      }));
    } catch (error) {
      console.error('Error marking as sold:', error);
      throw error;
    }
  },

  deleteProduct: async (id) => {
    try {
      const { error } = await supabase
        .from('products')
        .delete()
        .eq('id', id);

      if (error) throw error;

      set(state => ({
        products: state.products.filter(product => product.id !== id)
      }));
    } catch (error) {
      console.error('Error deleting product:', error);
      throw error;
    }
  },
}));