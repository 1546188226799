import React, { useEffect } from 'react';
import { useAdminStore } from '../stores/adminStore';
import { 
  Users, 
  Package, 
  DollarSign, 
  TrendingUp,
  UserPlus,
  ShoppingBag,
  Percent,
  Activity
} from 'lucide-react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

function Admin() {
  const { stats, fetchAdminStats } = useAdminStore();

  useEffect(() => {
    fetchAdminStats();
  }, [fetchAdminStats]);

  return (
    <div className="pt-6 pb-20">
      <h1 className="text-2xl font-bold mb-6">Administration</h1>

      <div className="grid gap-6">
        {/* User Statistics */}
        <div className="bg-gray-800 p-6 rounded-lg">
          <div className="flex items-center gap-3 mb-4">
            <Users className="h-6 w-6 text-red-500" />
            <h2 className="text-xl font-semibold">Statistiques utilisateurs</h2>
          </div>
          
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            <div className="bg-gray-700/50 p-4 rounded-lg">
              <div className="flex items-center gap-2 text-gray-400 mb-1">
                <Users className="h-4 w-4" />
                <p>Total utilisateurs</p>
              </div>
              <p className="text-3xl font-bold">{stats.totalUsers}</p>
            </div>

            <div className="bg-gray-700/50 p-4 rounded-lg">
              <div className="flex items-center gap-2 text-gray-400 mb-1">
                <Activity className="h-4 w-4" />
                <p>Utilisateurs actifs</p>
              </div>
              <p className="text-3xl font-bold">{stats.activeUsers}</p>
            </div>

            <div className="bg-gray-700/50 p-4 rounded-lg">
              <div className="flex items-center gap-2 text-gray-400 mb-1">
                <UserPlus className="h-4 w-4" />
                <p>Nouveaux (30j)</p>
              </div>
              <p className="text-3xl font-bold">{stats.lastMonthUsers}</p>
            </div>
          </div>
        </div>

        {/* Sales Statistics */}
        <div className="bg-gray-800 p-6 rounded-lg">
          <div className="flex items-center gap-3 mb-4">
            <DollarSign className="h-6 w-6 text-red-500" />
            <h2 className="text-xl font-semibold">Statistiques ventes</h2>
          </div>
          
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
            <div className="bg-gray-700/50 p-4 rounded-lg">
              <div className="flex items-center gap-2 text-gray-400 mb-1">
                <ShoppingBag className="h-4 w-4" />
                <p>Total ventes</p>
              </div>
              <p className="text-3xl font-bold">{stats.totalSales}</p>
            </div>

            <div className="bg-gray-700/50 p-4 rounded-lg">
              <div className="flex items-center gap-2 text-gray-400 mb-1">
                <TrendingUp className="h-4 w-4" />
                <p>Chiffre d'affaires</p>
              </div>
              <p className="text-3xl font-bold">{stats.totalRevenue}€</p>
            </div>

            <div className="bg-gray-700/50 p-4 rounded-lg">
              <div className="flex items-center gap-2 text-gray-400 mb-1">
                <Percent className="h-4 w-4" />
                <p>Marge moyenne</p>
              </div>
              <p className="text-3xl font-bold">{stats.averageMargin}%</p>
            </div>

            <div className="bg-gray-700/50 p-4 rounded-lg">
              <div className="flex items-center gap-2 text-gray-400 mb-1">
                <DollarSign className="h-4 w-4" />
                <p>Ventes (30j)</p>
              </div>
              <p className="text-3xl font-bold">{stats.lastMonthSales}</p>
            </div>
          </div>

          {/* Sales Chart */}
          <div className="bg-gray-700/50 p-4 rounded-lg">
            <h3 className="text-lg font-semibold mb-4">Évolution du chiffre d'affaires</h3>
            <div className="h-[300px]">
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={stats.salesData}>
                  <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
                  <XAxis 
                    dataKey="date" 
                    stroke="#9CA3AF"
                    tickFormatter={(date) => format(new Date(date), 'dd MMM', { locale: fr })}
                  />
                  <YAxis 
                    stroke="#9CA3AF"
                    tickFormatter={(value) => `${value}€`}
                  />
                  <Tooltip 
                    contentStyle={{ 
                      backgroundColor: '#1F2937',
                      border: 'none',
                      borderRadius: '0.5rem',
                      color: '#F3F4F6'
                    }}
                    formatter={(value: number) => [`${value}€`, 'Chiffre d\'affaires']}
                    labelFormatter={(date) => format(new Date(date), 'dd MMMM yyyy', { locale: fr })}
                  />
                  <Line 
                    type="monotone" 
                    dataKey="revenue" 
                    stroke="#EF4444" 
                    strokeWidth={2}
                    dot={false}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>

        {/* Product Statistics */}
        <div className="bg-gray-800 p-6 rounded-lg">
          <div className="flex items-center gap-3 mb-4">
            <Package className="h-6 w-6 text-red-500" />
            <h2 className="text-xl font-semibold">Statistiques produits</h2>
          </div>
          
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
            <div className="bg-gray-700/50 p-4 rounded-lg">
              <div className="flex items-center gap-2 text-gray-400 mb-1">
                <Package className="h-4 w-4" />
                <p>Total produits</p>
              </div>
              <p className="text-3xl font-bold">{stats.totalProducts}</p>
            </div>

            {Object.entries(stats.productsByCategory).map(([category, count]) => (
              <div key={category} className="bg-gray-700/50 p-4 rounded-lg">
                <p className="text-gray-400 mb-1 capitalize">{category}</p>
                <p className="text-3xl font-bold">{count}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Admin;