import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuthStore } from "../stores/authStore";
import { useCompanyStore } from "../stores/companyStore";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import {
  User,
  LogOut,
  Bell,
  Mail,
  Globe,
  Shield,
  HelpCircle,
  ExternalLink,
  Home,
  Building2,
  Crown,
  Clock,
  Lock,
  Calendar,
} from "lucide-react";
import { useNavigate } from "react-router-dom";
import UpdatePasswordModal from "../components/UpdatePasswordModal";
import { SubscriptionComponent } from "../components/SubscriptionComponent";

function Settings() {
  const { t, i18n } = useTranslation();
  const { logout, user } = useAuthStore();
  const { type, microStatus, setType, setMicroStatus } = useCompanyStore();
  const [emailNotifications, setEmailNotifications] = useState(true);
  const [pushNotifications, setPushNotifications] = useState(true);
  const navigate = useNavigate();
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);

  const handleLanguageChange = (lang: string) => {
    i18n.changeLanguage(lang);
  };

  const memberSince = user?.created_at
    ? format(new Date(user.created_at), "dd MMMM yyyy", { locale: fr })
    : "";

  return (
    <div className="pt-6 pb-20 max-w-2xl mx-auto px-4">
      <h1 className="text-2xl font-bold mb-6">{t("settings.title")}</h1>

      <div className="space-y-6">
        {/* Mon compte */}
        <div className="bg-gray-800 rounded-lg overflow-hidden">
          <div className="p-4 border-b border-gray-700">
            <div className="flex items-center gap-3">
              <User className="h-5 w-5 text-gray-400" />
              <h2 className="text-lg font-semibold">Mon compte</h2>
            </div>
          </div>
          <div className="p-4">
            <div className="flex items-center gap-4 mb-4">
              <div className="bg-gray-700 rounded-full p-4">
                <User className="h-8 w-8 text-gray-400" />
              </div>
              <div>
                <h3 className="font-medium">{user?.email}</h3>
                <div className="flex items-center gap-2 text-sm text-gray-400">
                  <Calendar className="h-4 w-4" />
                  <span>Membre depuis le {memberSince}</span>
                </div>
              </div>
            </div>

            <div className="space-y-2">
              <button
                onClick={() => setIsPasswordModalOpen(true)}
                className="w-full p-3 bg-gray-700 rounded-lg hover:bg-gray-600 transition-colors flex items-center justify-between"
              >
                <div className="flex items-center gap-2">
                  <Lock className="h-4 w-4 text-gray-400" />
                  <span>Modifier le mot de passe</span>
                </div>
                <ExternalLink className="h-4 w-4 text-gray-400" />
              </button>

              <button
                onClick={logout}
                className="w-full p-3 bg-red-500/10 text-red-500 rounded-lg hover:bg-red-500/20 transition-colors flex items-center justify-center gap-2"
              >
                <LogOut className="h-4 w-4" />
                <span>Se déconnecter</span>
              </button>
            </div>
          </div>
        </div>

        {/* Section Abonnement */}
        <SubscriptionComponent />

        {/* Type d'entreprise */}
        <div className="bg-gray-800 rounded-lg overflow-hidden">
          <div className="p-4 border-b border-gray-700">
            <div className="flex items-center gap-3">
              <Building2 className="h-5 w-5 text-gray-400" />
              <h2 className="text-lg font-semibold">Type d'entreprise</h2>
            </div>
          </div>
          <div className="p-4 space-y-4">
            <div className="grid grid-cols-2 gap-4">
              {[
                { value: null, label: "Pas d'entreprise" },
                { value: "micro", label: "Micro-entreprise" },
                { value: "eurl", label: "EURL" },
                { value: "sasu", label: "SASU" },
                { value: "sarl", label: "SARL" },
              ].map(({ value, label }) => (
                <button
                  key={value || "none"}
                  onClick={() => setType(value as any)}
                  className={`p-3 rounded-lg text-left transition-colors ${
                    type === value || (type === null && value === null)
                      ? "bg-red-500 text-white"
                      : "bg-gray-700 hover:bg-gray-600"
                  }`}
                >
                  {label}
                </button>
              ))}
            </div>

            {type === "micro" && (
              <div className="pt-4 border-t border-gray-700">
                <h3 className="text-sm font-medium mb-3">Statut ACRE</h3>
                <div className="grid grid-cols-2 gap-4">
                  {[
                    { value: "acre", label: "Avec ACRE" },
                    { value: "normal", label: "Sans ACRE" },
                  ].map(({ value, label }) => (
                    <button
                      key={value}
                      onClick={() => setMicroStatus(value as any)}
                      className={`p-3 rounded-lg text-left transition-colors ${
                        microStatus === value
                          ? "bg-red-500 text-white"
                          : "bg-gray-700 hover:bg-gray-600"
                      }`}
                    >
                      {label}
                    </button>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Langue */}
        <div className="bg-gray-800 rounded-lg overflow-hidden">
          <div className="p-4 border-b border-gray-700">
            <div className="flex items-center gap-3">
              <Globe className="h-5 w-5 text-gray-400" />
              <h2 className="text-lg font-semibold">Langue</h2>
            </div>
          </div>
          <div className="p-4 grid grid-cols-2 gap-4">
            {[
              { code: "fr", label: "Français", flag: "🇫🇷" },
              { code: "en", label: "English", flag: "🇬🇧" },
              { code: "de", label: "Deutsch", flag: "🇩🇪" },
              { code: "es", label: "Español", flag: "🇪🇸" },
            ].map(({ code, label, flag }) => (
              <button
                key={code}
                onClick={() => handleLanguageChange(code)}
                className={`py-3 px-4 rounded-lg hover:bg-gray-700 transition-colors flex items-center gap-3 ${
                  i18n.language === code ? "bg-gray-700" : "bg-gray-800"
                }`}
              >
                <span className="text-xl">{flag}</span>
                <span>{label}</span>
              </button>
            ))}
          </div>
        </div>

        {/* Notifications */}
        <div className="bg-gray-800 rounded-lg overflow-hidden">
          <div className="p-4 border-b border-gray-700">
            <div className="flex items-center gap-3">
              <Bell className="h-5 w-5 text-gray-400" />
              <h2 className="text-lg font-semibold">Notifications</h2>
            </div>
          </div>
          <div className="p-4 space-y-4">
            <label className="flex items-center justify-between p-3 bg-gray-700/50 rounded-lg">
              <div className="flex items-center gap-3">
                <Mail className="h-5 w-5 text-gray-400" />
                <div>
                  <span className="block">Notifications par email</span>
                  <span className="text-sm text-gray-400">
                    Recevez des mises à jour par email
                  </span>
                </div>
              </div>
              <input
                type="checkbox"
                checked={emailNotifications}
                onChange={(e) => setEmailNotifications(e.target.checked)}
                className="w-5 h-5 rounded border-gray-600 bg-gray-700 text-red-500 focus:ring-red-500"
              />
            </label>
            <label className="flex items-center justify-between p-3 bg-gray-700/50 rounded-lg">
              <div className="flex items-center gap-3">
                <Bell className="h-5 w-5 text-gray-400" />
                <div>
                  <span className="block">Notifications push</span>
                  <span className="text-sm text-gray-400">
                    Recevez des notifications push
                  </span>
                </div>
              </div>
              <input
                type="checkbox"
                checked={pushNotifications}
                onChange={(e) => setPushNotifications(e.target.checked)}
                className="w-5 h-5 rounded border-gray-600 bg-gray-700 text-red-500 focus:ring-red-500"
              />
            </label>
          </div>
        </div>

        {/* Version */}
        <div className="text-center text-sm text-gray-500">Version 1.2.2</div>
      </div>

      <UpdatePasswordModal
        isOpen={isPasswordModalOpen}
        onClose={() => setIsPasswordModalOpen(false)}
      />
    </div>
  );
}

export default Settings;
