import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Lock } from "lucide-react";
import SubscriptionCard from "../components/SubscriptionCard";
import { useSubscriptionStore } from "../stores/subscriptionStore";
import { useSubscriptionStatus } from "../contexts/SubscriptionContext";

const SubscriptionRequiredPopup = () => {
  const navigate = useNavigate();

  return (
    <div
      className="fixed inset-x-0 top-0"
      style={{
        bottom: "calc(56px + env(safe-area-inset-bottom))",
        background: "rgba(0, 0, 0, 0.5)",
        backdropFilter: "blur(5px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "16px",
        zIndex: 40,
      }}
    >
      <div className="bg-gray-800 rounded-lg max-w-md w-full p-6 space-y-6">
        <div className="flex justify-center">
          <div className="bg-red-500/10 p-3 rounded-full">
            <Lock className="h-8 w-8 text-red-500" />
          </div>
        </div>

        <h2 className="text-xl font-semibold text-center">
          Contenu réservé aux abonnés ou période d'essai active
        </h2>

        <p className="text-gray-400 text-center">
          Ce contenu exclusif est disponible uniquement pour nos utilisateurs
          ayant un abonnement actif ou bénéficiant d'une période d'essai en
          cours.
        </p>

        <p className="text-gray-400 text-center">
          Abonnez-vous dès maintenant pour accéder à l'intégralité de nos
          ressources et profiter d'une expérience enrichie.
        </p>

        <div className="flex justify-center pt-2">
          <button
            onClick={() => navigate("/settings")}
            className="bg-yellow-500 text-white px-6 py-2 rounded-lg hover:bg-yellow-600 transition-colors"
          >
            3 jours d'essai gratuit
          </button>
        </div>
        <div className="flex justify-center pt-0">
          <button
            onClick={() => navigate("/settings")}
            className="bg-red-500 text-white px-6 py-2 rounded-lg hover:bg-red-600 transition-colors"
          >
            S'abonner maintenant
          </button>
        </div>
      </div>
    </div>
  );
};

export const SubscriptionComponent = () => {
  const { startTrial, subscribe } = useSubscriptionStore();
  const [isLoading, setIsLoading] = useState(false);

  const { subscriptionStatus, refreshSubscriptionStatus } =
    useSubscriptionStatus();

  const {
    status,
    hasTrialBeenUsed,
    isTrialExpired,
    subscriptionEndDate,
    trialEndDate,
    subscriptionLoading,
    customerId,
  } = subscriptionStatus;

  const isActiveUser = status === "active";

  const handleStartTrial = async () => {
    try {
      setIsLoading(true);
      await startTrial();
      refreshSubscriptionStatus();
    } catch (error) {
      console.error("Erreur:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubscribe = async () => {
    try {
      setIsLoading(true);
      await subscribe();
      refreshSubscriptionStatus();
    } catch (error) {
      console.error("Erreur:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="space-y-4 mb-6">
      <h2 className="text-xl font-semibold">Abonnement</h2>
      <div className="grid gap-4">
        <>
          <SubscriptionCard
            type="trial"
            onSubscribe={handleStartTrial}
            isLoading={isLoading}
          />

          <SubscriptionCard
            type="premium"
            onSubscribe={handleSubscribe}
            isLoading={isLoading}
            isActiveUser={isActiveUser}
          />
        </>
      </div>
    </div>
  );
};

export const SubscriptionEndedComponent = () => {
  const { subscriptionStatus } = useSubscriptionStatus();
  const [statusChecked, setStatusChecked] = useState(false);

  useEffect(() => {
    // Attendez que le statut soit chargé pour marquer comme vérifié
    if (subscriptionStatus && !subscriptionStatus.subscriptionLoading) {
      setStatusChecked(true);
    }
  }, [subscriptionStatus]);

  const isActiveUser = subscriptionStatus.status === "active";

  if (!statusChecked) {
    // Ne rien afficher tant que le statut n'est pas vérifié
    return null;
  }

  if (!isActiveUser) {
    return <SubscriptionRequiredPopup />;
  }

  return null; // Retourne rien si l'utilisateur est actif
};
