import { supabase } from '../lib/supabase';
import { v4 as uuidv4 } from 'uuid';
import imageCompression from 'browser-image-compression';
import heic2any from 'heic2any';

const BUCKET_NAME = import.meta.env.VITE_SUPABASE_STORAGE_BUCKET;
const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2MB
const MAX_DIMENSION = 1200;

const compressImage = async (file: File): Promise<File> => {
  // Vérifier si le fichier est une image
  if (!file.type.startsWith('image/')) {
    throw new Error('Le fichier doit être une image');
  }

  const options = {
    maxSizeMB: MAX_FILE_SIZE / (1024 * 1024),
    maxWidthOrHeight: MAX_DIMENSION,
    useWebWorker: true,
    fileType: file.type,
  };

  try {
    return await imageCompression(file, options);
  } catch (error) {
    console.error('Erreur de compression:', error);
    return file;
  }
};

const convertHeicToJpeg = async (file: File): Promise<File> => {
  if (file.type === 'image/heic' || file.type === 'image/heif') {
    try {
      const blob = await heic2any({
        blob: file,
        toType: 'image/jpeg',
        quality: 0.8
      });
      return new File([blob], file.name.replace(/\.(heic|heif)$/i, '.jpg'), {
        type: 'image/jpeg'
      });
    } catch (error) {
      console.error('Erreur conversion HEIC:', error);
      throw new Error('Impossible de convertir l\'image HEIC');
    }
  }
  return file;
};

export async function uploadImage(file: File): Promise<string> {
  try {
    // Vérifier le type de fichier
    if (!file.type.match(/^image\/(jpeg|jpg|png|gif|heic|heif|webp)$/i)) {
      throw new Error('Format d\'image non supporté');
    }

    // Convertir HEIC/HEIF en JPEG si nécessaire
    let processedFile = await convertHeicToJpeg(file);
    
    // Compression de l'image
    processedFile = await compressImage(processedFile);
    
    // Générer un nom de fichier unique
    const fileExt = processedFile.type.split('/')[1];
    const fileName = `${uuidv4()}.${fileExt}`;

    // Upload du fichier
    const { error: uploadError, data } = await supabase.storage
      .from(BUCKET_NAME)
      .upload(`images/${fileName}`, processedFile, {
        cacheControl: '3600',
        upsert: true,
        contentType: processedFile.type
      });

    if (uploadError) {
      console.error('Erreur upload:', uploadError);
      throw new Error('Erreur lors de l\'upload de l\'image');
    }

    if (!data?.path) {
      throw new Error('Chemin du fichier non disponible');
    }

    // Récupérer l'URL publique
    const { data: { publicUrl } } = supabase.storage
      .from(BUCKET_NAME)
      .getPublicUrl(data.path);

    if (!publicUrl) {
      throw new Error('Impossible de générer l\'URL publique');
    }

    return publicUrl;
  } catch (error) {
    console.error('Erreur upload:', error);
    throw error instanceof Error ? error : new Error('Une erreur inattendue est survenue');
  }
}