import { create } from 'zustand';
import { supabase } from '../lib/supabase';
import { persist } from 'zustand/middleware';

interface AuthStore {
  isAuthenticated: boolean;
  user: any | null;
  session: any | null;
  login: (email: string, password: string) => Promise<void>;
  signup: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  setSession: (session: any) => void;
}

export const useAuthStore = create<AuthStore>()(
  persist(
    (set) => ({
      isAuthenticated: false,
      user: null,
      session: null,

      setSession: (session) => {
        set({
          isAuthenticated: !!session,
          user: session?.user || null,
          session,
        });
      },

      signup: async (email: string, password: string) => {
        try {
          const { data, error } = await supabase.auth.signUp({
            email,
            password,
          });
          
          if (error) {
            if (error.message.includes('User already registered')) {
              throw new Error('Un compte existe déjà avec cet email');
            }
            throw error;
          }

          // Ne pas connecter automatiquement l'utilisateur
          return;
        } catch (error: any) {
          console.error('Erreur d\'inscription:', error);
          throw error;
        }
      },

      login: async (email: string, password: string) => {
        try {
          const { data, error } = await supabase.auth.signInWithPassword({
            email,
            password,
          });
          
          if (error) {
            if (error.message.includes('Invalid login credentials')) {
              throw new Error('Email ou mot de passe incorrect');
            }
            throw error;
          }

          set({
            isAuthenticated: true,
            user: data.user,
            session: data.session,
          });
        } catch (error) {
          console.error('Erreur de connexion:', error);
          throw error;
        }
      },

      logout: async () => {
        try {
          const { error } = await supabase.auth.signOut();
          if (error) throw error;

          set({
            isAuthenticated: false,
            user: null,
            session: null,
          });

          window.location.href = '/';
        } catch (error) {
          console.error('Erreur de déconnexion:', error);
          throw error;
        }
      },
    }),
    {
      name: 'auth-storage',
      partialize: (state) => ({
        isAuthenticated: state.isAuthenticated,
        user: state.user,
        session: state.session,
      }),
    }
  )
);