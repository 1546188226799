import React, { useState } from "react";
import { supabase } from "../lib/supabase";
import { Loader2, ShoppingBag, CheckCircle, AlertCircle } from "lucide-react";

function ResetPassword() {
  const [newPassword, setNewPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleResetPassword = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
      const { error } = await supabase.auth.updateUser({
        password: newPassword,
      });

      if (error) throw error;

      setSuccess(true);
      setNewPassword("");
    } catch (err: any) {
      console.error("Reset password error:", err);
      setError(
        "Impossible de mettre à jour le mot de passe. Veuillez réessayer."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-900 px-4">
      <div className="w-full max-w-md space-y-8">
        <div className="text-center">
          <div className="flex justify-center mb-6">
            <div className="bg-red-500 p-4 rounded-2xl">
              <ShoppingBag className="h-12 w-12 text-white" />
            </div>
          </div>
          <h2 className="text-3xl font-extrabold text-white mb-2">Stocktor</h2>
          <h1 className="text-gray-400 text-2xl font-bold text-center">
            Réinitialiser votre mot de passe
          </h1>{" "}
        </div>

        {success ? (
          <div className="bg-green-500/10 border border-green-500/20 rounded-lg p-3 flex items-center gap-2">
            <CheckCircle className="h-5 w-5 text-green-500" />
            <p className="text-green-500">
              Mot de passe mis à jour avec succès !
            </p>
          </div>
        ) : (
          <form
            onSubmit={handleResetPassword}
            className="bg-gray-800 p-6 rounded-lg space-y-4">
            {error && (
              <div className="bg-red-500/10 border border-red-500/20 rounded-lg p-3 flex items-center gap-2">
                <AlertCircle className="h-5 w-5 text-red-500" />
                <p className="text-red-500">{error}</p>
              </div>
            )}
            <div>
              <label className="text-sm font-medium text-gray-300 mb-1 block">
                Nouveau mot de passe
              </label>
              <input
                type="password"
                className="appearance-none block w-full px-3 py-2 border border-gray-700 rounded-lg bg-gray-700 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-red-500"
                placeholder="Entrez un nouveau mot de passe"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
            </div>
            <button
              type="submit"
              className="w-full bg-red-500 hover:bg-red-600 text-white py-2 rounded-lg flex items-center justify-center gap-2"
              disabled={loading}>
              {loading ? (
                <>
                  <Loader2 className="h-5 w-5 animate-spin" />
                  Mise à jour...
                </>
              ) : (
                "Réinitialiser"
              )}
            </button>
          </form>
        )}
      </div>
    </div>
  );
}

export default ResetPassword;
