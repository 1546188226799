import React from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  ShoppingBag, 
  BarChart2, 
  Package, 
  Clock, 
  Shield, 
  Database, 
  Cloud, 
  Lock, 
  BarChart3, 
  Smartphone, 
  Globe, 
  ExternalLink,
  Instagram 
} from 'lucide-react';
import Logo from '../components/Logo';

function Landing() {
  const navigate = useNavigate();

  const goToSignup = () => {
    navigate('/login', { state: { isSignup: true } });
  };

  const openSupportEmail = () => {
    window.open('https://mail.google.com/mail/?view=cm&fs=1&to=support@stocktor.fr', '_blank');
  };

  const openInstagram = () => {
    window.open('https://www.instagram.com/stocktor_officiel', '_blank');
  };

  return (
    <div className="min-h-screen bg-gray-900">
      {/* Header */}
      <header className="fixed top-0 left-0 right-0 bg-gray-800/80 backdrop-blur-sm border-b border-gray-700 z-50 safe-header">
  <div className="max-w-7xl mx-auto px-4 h-20 flex items-center justify-between">
    <Logo />
    <div className="flex gap-4">
      <button
        onClick={() => navigate('/login')}
        className="px-4 py-2 text-gray-300 hover:text-white transition-colors"
      >
        Se connecter
      </button>
      <button
        onClick={goToSignup}
        className="px-1 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors"
      >
        S'inscrire
      </button>
    </div>
  </div>
</header>


      {/* Hero Section */}
      <section className="pt-32 pb-20 px-4">
        <div className="max-w-4xl mx-auto text-center">
          <h1 className="text-5xl font-bold mb-6 bg-gradient-to-r from-red-500 to-purple-500 text-transparent bg-clip-text">
            Boostez votre business de revente avec simplicité
          </h1>
          <p className="text-xl text-gray-400 mb-8">
            Stocktor est l'outil tout-en-un pour les revendeurs qui veulent suivre leur inventaire,
            leurs commandes et leurs profits en temps réel. Optimisez vos marges, automatisez votre suivi
            et développez votre activité avec des outils professionnels.
          </p>
          <div className="flex gap-4 justify-center">
            <button
              onClick={goToSignup}
              className="px-8 py-3 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors text-lg font-medium"
            >
              Commencer maintenant
            </button>
            <a
              href="#features"
              className="px-8 py-3 bg-gray-800 text-white rounded-lg hover:bg-gray-700 transition-colors text-lg font-medium"
            >
              En savoir plus
            </a>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section id="features" className="py-5">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Tout ce dont vous avez besoin</h2>
          <div className="grid md:grid-cols-3 gap-8">
            <div className="bg-gray-800/50 rounded-xl p-6 border border-gray-700">
              <div className="bg-red-500/10 w-12 h-12 rounded-lg flex items-center justify-center mb-4">
                <Package className="h-6 w-6 text-red-500" />
              </div>
              <h3 className="text-xl font-semibold mb-2">Gestion de stock avancée</h3>
              <p className="text-gray-400">
                Suivez votre inventaire en temps réel avec des photos HD, codes-barres et étiquettes personnalisées
              </p>
            </div>

            <div className="bg-gray-800/50 rounded-xl p-6 border border-gray-700">
              <div className="bg-red-500/10 w-12 h-12 rounded-lg flex items-center justify-center mb-4">
                <BarChart2 className="h-6 w-6 text-red-500" />
              </div>
              <h3 className="text-xl font-semibold mb-2">Analyse des profits</h3>
              <p className="text-gray-400">
                Visualisez vos marges et votre rentabilité avec des graphiques détaillés et des prévisions
              </p>
            </div>

            <div className="bg-gray-800/50 rounded-xl p-6 border border-gray-700">
              <div className="bg-red-500/10 w-12 h-12 rounded-lg flex items-center justify-center mb-4">
                <Clock className="h-6 w-6 text-red-500" />
              </div>
              <h3 className="text-xl font-semibold mb-2">Suivi des commandes</h3>
              <p className="text-gray-400">
                Gérez vos commandes avec notifications automatiques et suivi des statuts en temps réel
              </p>
            </div>

            <div className="bg-gray-800/50 rounded-xl p-6 border border-gray-700">
              <div className="bg-red-500/10 w-12 h-12 rounded-lg flex items-center justify-center mb-4">
                <Database className="h-6 w-6 text-red-500" />
              </div>
              <h3 className="text-xl font-semibold mb-2">Base de données</h3>
              <p className="text-gray-400">
                Accédez à une base de données sécurisée, conçue pour gérer vos informations avec fiabilité et performances
              </p>
            </div>

            <div className="bg-gray-800/50 rounded-xl p-6 border border-gray-700">
              <div className="bg-red-500/10 w-12 h-12 rounded-lg flex items-center justify-center mb-4">
                <Cloud className="h-6 w-6 text-red-500" />
              </div>
              <h3 className="text-xl font-semibold mb-2">Sauvegarde cloud</h3>
              <p className="text-gray-400">
                Vos données sont automatiquement sauvegardées et accessibles depuis n'importe où
              </p>
            </div>

            <div className="bg-gray-800/50 rounded-xl p-6 border border-gray-700">
              <div className="bg-red-500/10 w-12 h-12 rounded-lg flex items-center justify-center mb-4">
                <Shield className="h-6 w-6 text-red-500" />
              </div>
              <h3 className="text-xl font-semibold mb-2">Sécurité maximale</h3>
              <p className="text-gray-400">
                Vos données sont protégées avec les dernières technologies de sécurité
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* New Features Section */}
      <section className="py-20 bg-gray-800/50">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Nouvelles fonctionnalités</h2>
          <div className="grid md:grid-cols-3 gap-8">
            <div className="bg-gray-800 rounded-xl p-6 border border-gray-700">
              <div className="bg-red-500/10 w-12 h-12 rounded-lg flex items-center justify-center mb-4">
                <BarChart3 className="h-6 w-6 text-red-500" />
              </div>
              <h3 className="text-xl font-semibold mb-2">Analyses avancées</h3>
              <p className="text-gray-400">
                Nouvelles métriques et graphiques pour mieux comprendre votre performance
              </p>
            </div>

            <div className="bg-gray-800 rounded-xl p-6 border border-gray-700">
              <div className="bg-red-500/10 w-12 h-12 rounded-lg flex items-center justify-center mb-4">
                <Smartphone className="h-6 w-6 text-red-500" />
              </div>
              <h3 className="text-xl font-semibold mb-2">Application mobile</h3>
              <p className="text-gray-400">
                Accédez à vos données en déplacement avec notre nouvelle app mobile
              </p>
            </div>

            <div className="bg-gray-800 rounded-xl p-6 border border-gray-700">
              <div className="bg-red-500/10 w-12 h-12 rounded-lg flex items-center justify-center mb-4">
                <Globe className="h-6 w-6 text-red-500" />
              </div>
              <h3 className="text-xl font-semibold mb-2">Multi-langues</h3>
              <p className="text-gray-400">
                Interface disponible en français, anglais, allemand et espagnol
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Use Cases Section */}
      <section className="py-20">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Pour tous les types de revente</h2>
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-6">
            <div className="bg-gray-800 rounded-xl overflow-hidden">
              <img 
                src="https://images.unsplash.com/photo-1552346154-21d32810aba3" 
                alt="Sneakers" 
                className="w-full h-48 object-cover"
              />
              <div className="p-4">
                <h3 className="font-semibold mb-2">Sneakers</h3>
                <p className="text-sm text-gray-400">
                  Gérez votre collection de sneakers et maximisez vos profits sur chaque paire
                </p>
              </div>
            </div>

            <div className="bg-gray-800 rounded-xl overflow-hidden">
              <img 
                src="https://images.unsplash.com/photo-1556905055-8f358a7a47b2" 
                alt="Streetwear" 
                className="w-full h-48 object-cover"
              />
              <div className="p-4">
                <h3 className="font-semibold mb-2">Streetwear</h3>
                <p className="text-sm text-gray-400">
                  Suivez vos vêtements et accessoires de marques avec précision
                </p>
              </div>
            </div>

            <div className="bg-gray-800 rounded-xl overflow-hidden">
              <img 
                src="https://leconservatoiredujeu.fr/wp-content/uploads/2020/06/fb_92360-2.jpg" 
                alt="Cartes" 
                className="w-full h-48 object-cover"
              />
              <div className="p-4">
                <h3 className="font-semibold mb-2">Cartes</h3>
                <p className="text-sm text-gray-400">
                  Cataloguez vos cartes de collection et suivez les prix du marché
                </p>
              </div>
            </div>

            <div className="bg-gray-800 rounded-xl overflow-hidden">
              <img 
                src="https://images.unsplash.com/photo-1468495244123-6c6c332eeece" 
                alt="Électronique" 
                className="w-full h-48 object-cover"
              />
              <div className="p-4">
                <h3 className="font-semibold mb-2">Électronique</h3>
                <p className="text-sm text-gray-400">
                  Gérez vos stocks d'appareils électroniques et leurs accessoires
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Why Choose Us Section */}
      <section className="py-20 bg-gray-800/50">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Pourquoi choisir Stocktor ?</h2>
          <div className="grid md:grid-cols-3 gap-8">
            <div className="bg-gray-800 rounded-xl p-6 border border-gray-700">
              <Lock className="h-8 w-8 text-red-500 mb-4" />
              <h3 className="text-xl font-semibold mb-2">Sécurité maximale</h3>
              <p className="text-gray-400">
                Vos données sont cryptées et sécurisées avec les dernières technologies. Accès sécurisé
                et authentification à deux facteurs disponible.
              </p>
            </div>

            <div className="bg-gray-800 rounded-xl p-6 border border-gray-700">
              <Database className="h-8 w-8 text-red-500 mb-4" />
              <h3 className="text-xl font-semibold mb-2">Performance</h3>
              <p className="text-gray-400">
                Application rapide et réactive, synchronisation instantanée entre tous vos appareils.
                Travaillez efficacement, même avec des milliers d'articles.
              </p>
            </div>

            <div className="bg-gray-800 rounded-xl p-6 border border-gray-700">
              <Shield className="h-8 w-8 text-red-500 mb-4" />
              <h3 className="text-xl font-semibold mb-2">Support premium</h3>
              <p className="text-gray-400">
                Support client réactif et personnalisé. Nous vous accompagnons dans la réussite de
                votre business avec des conseils d'experts.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-20">
        <div className="max-w-3xl mx-auto px-4 text-center">
          <h2 className="text-4xl font-bold mb-6">Prêt à développer votre business ?</h2>
          <p className="text-xl text-gray-400 mb-8">
            Rejoignez des milliers de revendeurs qui utilisent Stocktor pour gérer leur activité.
            Commencez dès maintenant !
          </p>
          <button
            onClick={goToSignup}
            className="px-8 py-3 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors text-lg font-medium"
          >
            Commencer maintenant
          </button>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-gray-800/50 py-12">
        <div className="max-w-7xl mx-auto px-4">
          <div className="grid md:grid-cols-4 gap-8">
            <div>
              <Logo />
              <p className="mt-4 text-gray-400 text-sm">
                La solution complète pour les revendeurs professionnels
              </p>
            </div>
            <div>
              <h4 className="font-medium mb-4">Produit</h4>
              <ul className="space-y-2 text-gray-400">
                <li><a href="#features" className="hover:text-white">Fonctionnalités</a></li>
                <li><a href="#" className="hover:text-white">Guide</a></li>
              </ul>
            </div>
            <div>
              <h4 className="font-medium mb-4">Support</h4>
              <ul className="space-y-2 text-gray-400">
                <li><a href="#" className="hover:text-white">Centre d'aide</a></li>
                <li><button onClick={openSupportEmail} className="hover:text-white">Contact</button></li>
                <li><button onClick={openInstagram} className="hover:text-white flex items-center gap-2">
                  <Instagram className="h-4 w-4" />
                  Instagram
                </button></li>
              </ul>
            </div>
            <div>
              <h4 className="font-medium mb-4">Légal</h4>
              <ul className="space-y-2 text-gray-400">
                <li><a href="/privacy" className="hover:text-white">Confidentialité</a></li>
                <li><a href="/terms" className="hover:text-white">CGU</a></li>
                <li><a href="/gdpr" className="hover:text-white">RGPD</a></li>
              </ul>
            </div>
          </div>
          <div className="border-t border-gray-700 mt-12 pt-8 text-center text-gray-400">
            <p>© 2024 Stocktor. Tous droits réservés.</p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Landing;
