import React, { useState } from "react";
import { supabase } from "../lib/supabase";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import { Crown, Clock, Loader2, CreditCard } from "lucide-react";
import { redirectToCheckout, redirectToCustomerPortal } from "../lib/stripe";
import { useSubscriptionStatus } from "../contexts/SubscriptionContext";

interface SubscriptionCardProps {
  type: "trial" | "premium";
  onSubscribe: () => void;
  isActiveUser?: boolean;
  isLoading?: boolean;
}

export default function SubscriptionCard({
  type,
  onSubscribe,
  isActiveUser = false,
  isLoading = false,
}: SubscriptionCardProps) {
  const { subscriptionStatus, refreshSubscriptionStatus } =
    useSubscriptionStatus();

  const {
    status,
    hasTrialBeenUsed,
    isTrialExpired,
    subscriptionEndDate,
    trialEndDate,
    subscriptionLoading,
    customerId,
  } = subscriptionStatus;

  const [error, setError] = useState<string | null>(null);

  const isDisabled =
    (type === "trial" && hasTrialBeenUsed) || status === "active" || isLoading;

  const handleClick = async (e: React.MouseEvent) => {
    e.preventDefault();
    if (isDisabled) return;

    try {
      // if (type === "premium") {
      // await redirectToCheckout();
      // } else {
      await onSubscribe();
      // }
    } catch (error) {
      console.error("Erreur lors de la redirection:", error);
    }
  };
  const handleManageSubscription = async () => {
    // setIsLoading(true);
    setError(null);

    try {
      const {
        data: { session },
      } = await supabase.auth.getSession();
      if (!session) throw new Error("Non authentifié");

      const response = await fetch(
        "https://tiuiecftvzbyikpuodan.supabase.co/functions/v1/manage-subscription",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${session.access_token}`,
          },
          body: JSON.stringify({ customerId }),
        }
      );

      if (!response.ok) {
        console.log(await response.text());

        throw new Error(
          "Impossible de créer une session de gestion d'abonnement."
        );
      }

      const { url } = await response.json();
      window.location.href = url; // Redirige l'utilisateur vers Stripe
    } catch (error: any) {
      console.error("Erreur:", error);
      setError(error.message || "Une erreur est survenue.");
    } finally {
      // setIsLoading(false);
    }
  };

  return (
    <div className="bg-gray-800 rounded-lg p-6 border border-gray-700">
      {type === "trial" ? (
        <>
          <div className="flex items-center gap-3 mb-4">
            <div className="bg-yellow-500/10 p-2 rounded-lg">
              <Clock className="h-6 w-6 text-yellow-500" />
            </div>
            <div>
              <h3 className="font-semibold">Essai gratuit</h3>
              <p className="text-sm text-gray-400">3 jours d'accès complet</p>
            </div>
          </div>

          {status === "trial" && trialEndDate && (
            <div className="mb-4 text-sm">
              <p className="text-yellow-500">
                Expire le{" "}
                {format(new Date(trialEndDate), "dd MMMM yyyy", { locale: fr })}
              </p>
            </div>
          )}

          {hasTrialBeenUsed && (
            <p className="text-sm text-gray-400 mb-4">
              Période d'essai déjà utilisée
            </p>
          )}
        </>
      ) : (
        <>
          <div className="flex items-center gap-3 mb-4">
            <div className="bg-red-500/10 p-2 rounded-lg">
              <Crown className="h-6 w-6 text-red-500" />
            </div>
            <div>
              <h3 className="font-semibold">Premium</h3>
              <p className="text-sm text-gray-400">Accès illimité</p>
            </div>
          </div>
          <div className="mb-4">
            <p className="text-2xl font-bold">
              14.99€<span className="text-sm text-gray-400">/mois</span>
            </p>
          </div>

          {status === "active" && (
            <div className="mb-4">
              <p className="text-sm text-gray-400">
                Prochain paiement le{" "}
                {subscriptionEndDate &&
                  format(new Date(subscriptionEndDate), "dd MMMM yyyy", {
                    locale: fr,
                  })}
              </p>
            </div>
          )}
        </>
      )}

      {status === "active" && type != "trial" ? (
        <button
          onClick={handleManageSubscription}
          className="mt-2 w-full flex items-center justify-center gap-2 bg-gray-700 text-white px-4 py-2 rounded-lg hover:bg-gray-600 transition-colors">
          <CreditCard className="h-4 w-4" />
          Gérer mon abonnement
        </button>
      ) : (
        <button
          onClick={handleClick}
          disabled={isDisabled}
          className={`w-full py-2 px-4 rounded-lg flex items-center justify-center gap-2 transition-colors ${
            type === "trial"
              ? "bg-yellow-500/10 text-yellow-500 hover:bg-yellow-500/20"
              : "bg-red-500 text-white hover:bg-red-600"
          } disabled:opacity-50 disabled:cursor-not-allowed`}>
          {isLoading ? (
            <Loader2 className="h-5 w-5 animate-spin" />
          ) : type === "trial" ? (
            "Commencer l'essai"
          ) : (
            "Devenir membre"
          )}
        </button>
      )}
    </div>
  );
}
